.getStarted{
  background: $primary;
}

.shar-navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.39);
  z-index: 100;
  .nav-link-active {
    color: $alternate-color !important;
  }

  .home-nav-logo {
    @media (max-width: $hd-desktop) {
      max-width: 200px;
      height: 36px;
      object-fit: contain;
    }
    @media (max-width: $hd-tab) {
      margin-left: calc(50% - 100px);
    }
  }

  .navbar-menu-list,
  .navbar-outer {
    @media (max-width: $hd-tab) {
      flex-direction: column;
    }
  }

  .shar-navbar-links {
    @media (max-width: $hd-tab) {
      display: flex;
      flex-direction: column;
    }
  }

  .navbar-toggler {
    @media (max-width: $hd-tab) {
      position: absolute;
      padding: 0;
      left: 16px;
    }
  }

  .navbar-outer {
    width: 100%;

    @media (max-width: $hd-desktop) {

      font-size: 1rem;

      .display-6 {
        font-size: 1.0625rem;
      }
    }

    @media (max-width: $hd-tab) {
      position: absolute;
      top: 69px;
      background: $secondary-color;
      left: 0px;
      z-index: 1;
      padding: 24px;
      min-height: 100vh;
      width: auto;
      min-width: 300px;
      border-top: 3px solid $alternate-color;
      animation: slideRight 0.2s ease-in-out;
      min-height: calc(100vh - 68px);
    }

    @media (max-width: $mobile) {
      width: 100%;
      border-top: none;
      top: 68px;
    }
  }

  @media(max-width: $hd-tab) {
    .collapsing{
      display: none;
    }
  }

  .navbar-toggler-icon {
    background-image: url('../assets/images/icons/hamburger-white.svg');
  }

  .navbar-toggler:not(.collapsed) {
    .navbar-toggler-icon {
      background-image: url('../assets/images/icons/close_icon_white.svg');
    }
  }
}

.shar-navbar,
.shar-footer {
  background: $secondary-color;
  ul {
    a {
      transition: 0.2s ease-in-out;
      &:hover {
        color: $alternate-color !important;
      }
    }
  }
}

.page-head {
  min-height: 240px;
  @media (max-width: $tab) {
    min-height: 100px;
  }
}

.custom-list {
  li {
    margin-left: 34px;
    &:before {
     content: '';
     width: 16px;
     height: 9px;
     background: url('../assets/images/li-arrow.svg');
     position: absolute;
     left: -34px;
     top: 10px;
    }
  }
}

.dual-list {
  width: max-content;
}

.text-decorator {
  &:after {
    content: '';
    width: 90px;
    height: 4px;
    background: $alternate-color;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 45px);
  }
}

.modal-close {
  font-size: 50px;
  float: right;
  height: auto;
}

.clearboth {
  clear: both;
}

.footer-modal {
  left: 0;
  bottom: 0;
  z-index: 1;
  .modal-close {
    &:hover {
      color: darken($white,20%);
    }
  }
}

.modal-outer {
  display: none;
}

.modal-inner {
  animation: slideUp 0.5s ease-in-out;
  background: $secondary-color;
  min-height: 505px;
}

.parallax-banner {
  min-height: 210px;
  background: {
    size: cover;
    position: center;
    repeat: no-repeat;
    attachment: fixed;
  }

  @media (max-width: $hd-tab) {
    background-attachment: inherit;
  }

  @media (max-width: $mobile) {
    min-height: 150px;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

//can be shared among all files
@media (max-width:990px ) {
  .w-md-auto {
    width: auto !important;
  }
}

.shar-text {

  @media (max-width: $hd-desktop) {

    h1 {
       font-size: 2.125rem;
    }

    .font-22 {
      font-size: 1.125rem;
    }

    .display-7 {
      font-size: 1rem;
    }
  }

  @media (max-width: $tab) {
    h1,
    .font-38 {
      font-size: 32px;
    }

    .font-22,
    .display-6 {
      font-size: 18px;
    }
  }

  @media (max-width: $mobile) {
    h1,
    .font-38 {
      font-size: 28px;
    }

    .display-7 {
      font-size: 16px;
    }
  }
}

.shar-images {
  img{
    @media (max-width: $hd-tab) {
      max-height: 350px;
      width: auto;
    }

    @media (max-width: $tab) {
      max-height: 300px;
    }

    @media (max-width: $mobile) {
      max-height: 250px;
    }
  }
}

.sec-hero {
  @media (max-width: $hd-desktop) {
    .display-7 {
      font-size: 1.125rem;
    }
  }
}

.nav-share {
  .navbar-toggler {
      padding: 0.25rem 1rem;
      transition: 0.3s ease-in-out;
  }
}

@keyframes slideRight {

  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.js-no-scroll {
  overflow: hidden;
  header {
    &:after {
    content: '';
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 10;
    }
  }
}
