body {
  height: 100%;
  position: relative;
}

.header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 23;
  height: 58px;
  background-color: $bg-header;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #BCBCBC;
  transition: 0.3s ease-in-out;

  .profile-width {
    width: 38px;
    height: 38px;
    object-fit: cover;

    @media (max-width: $tab) {
      border: 3px solid $profile-border;
      background: $profile-border;
      box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
    }
  }

  .header-admin-dropdown {
    width: 66px;
    min-width: 66px;
    padding: 0;
    background: transparent;
    border: none !important;
    box-shadow: none !important;

    + .dropdown-card  {
      overflow: hidden;
    }

    &:after {
      display: none;
    }

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }

    &:active {
      background: transparent !important;
    }

    &[aria-expanded="true"] {
      background: transparent !important;
      &:focus {
        box-shadow: none;
      }
    }

    @media(max-width: $fhd-tab){
      text-align: right;
    }

    @media(max-width: $hd-tab){
      text-align: right;
    }
  }

  .header-dropdown-icon {
    width: 20px;
  }

  @media (max-width: $fhd-tab) {
    &.header-unpin {
      transform: translateY(-100%);

    ~ .container-fluid  #inner-nav {
        transform: translateY(-150%);
      }
    }
  }

  @media (max-width: $hd-tab) {
    &.header-unpin {
      transform: translateY(-100%);

    ~ .container-fluid  #inner-nav {
        transform: translateY(-150%);
      }
    }
  }
}

.scrollY {
  overflow: auto;
  height: 100%;
}

.main-container {
  @extend .d-flex;
  @extend .h-100;
  flex-direction: column;
}

.ethos-container{
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;
}

.page-container {
 
  padding-left: 55px;
  position: relative;
  margin-top: 58px;
  height: calc(100vh - 58px);
  @extend .scrollY;
  @extend .scroll-y;

  @media(max-width: $fhd-tab) {
    padding-left: 0;
  }

  @media(max-width: $hd-tab) {
    padding-left: 0;
  }

  @media (max-width: $fhd-tab) {
    height: auto;
  }

  @media (max-width: $tab) {
    height: auto;
  }

  
}

.dropdown-card {
  background: $card-bg-white;
  box-shadow: 1px 7px 15px 0 rgba(68,68,68,0.30);
  border-radius: 6px;

  @media (max-width: $hd-desktop) {
    min-width: 140px;

    a {
      padding: 12px !important;
      font-size: 0.875rem;
    }
  }
}

.header-logo {
  img {
    max-width: 200px;
    max-height: 26px;
    object-fit: cover;
  }

  @media (max-width: $fhd-tab) {
    max-width: calc(100% - 175px);
    margin-left: 45px;
    
    + span.mr-30.ml-auto {
      margin-right: 0;
      
      svg {
        width: 25px;
        height: 25px  
      }
    }
 }

 @media (max-width: $hd-tab) {
    max-width: calc(100% - 175px);
    margin-left: 45px;
    
    + span.mr-30.ml-auto {
      margin-right: 0;
      
      svg {
        width: 25px;
        height: 25px  
      }
    }
 }
}

.navbar {
  padding: 0;
}

.shar-shadow{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 10;
}

.sweet-loading {
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);

  > div {
      border-color: $primary !important;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      border-width: 4px;
  }
}


.header-admin-outer {
  @media (max-width: $fhd-tab) {
    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-image: linear-gradient(180deg, #F9C254 0%, #F18F2A 100%);
      bottom: 4px;
      right: -3px;
      z-index: 1;
    }
  }

  @media (max-width: $hd-tab) {
    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-image: linear-gradient(180deg, #F9C254 0%, #F18F2A 100%);
      bottom: 4px;
      right: -3px;
      z-index: 1;
    }
  }
}
