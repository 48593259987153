.close-btn-setup {
  line-height: 1;
  margin-top: -14px;
  margin-bottom: -9px;
  margin-right: -10px;
}

.payout {

  &-right-panel {
    height: 100%;
    min-width: 105px;
    min-height: 105px;
    border-radius: 0 4px 4px 0;
    
    @media (max-width: $mobile) {
      height: auto;
      min-height: 100%;
    }
  }

}
