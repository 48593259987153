@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
$n-skewY: skewY(-5deg);
$skewY: skewY(5deg);
body, h1, h2, h3, h4, h6, a, button, div, span, p, label {
  font-family: 'Inter', sans-serif !important;
}
.font-60 {
  font-size: 58px;
}
.font-50 {
  font-size: 50px;
}
.font-48 {
  font-size: 48px;
}
.font-46 {
  font-size: 46px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-25 {
  font-size: 25px;
}
.b-300{
  font-weight: 300;
}
.b-400{
  font-weight: 400;
}
.b-500{
  font-weight: 500;
}
.b-700{
  font-weight: 700;
}
.b-900{
  font-weight: 900;
}
.b-500 {
  font-weight: 500 !important;
}
.b-600 {
  font-weight: 600 !important;
}
.line-height-60 {
  line-height: 62px;
}
.line-height-50 {
  line-height: 50px;
}
.n-skewY {
  transform: $n-skewY;
}
.skewY {
  transform: $skewY;
}
.remove-skew {
  transform: skew(0);
}
.text-light-blue {
  color: $primary;
}
.text-black {
color: $text-black;
}
.text-dark-blue {
  color: #0a1c26;
}
.text-blue-mute {
  color: #b2e7ff;
}
.text-black-mute {
  color: rgba($text-black, 0.5) ;
}
.button-shadow {
  box-shadow: 0px 167px 208px rgba(7, 48, 73, 0.12), 0px 69.7686px 86.8974px rgba(7, 48, 73, 0.0862625), 0px 37.3016px 46.4595px rgba(7, 48, 73, 0.0715329), 0px 20.911px 26.0448px rgba(7, 48, 73, 0.06), 0px 11.1057px 13.8322px rgba(7, 48, 73, 0.0484671), 0px 4.62132px 5.75589px rgba(7, 48, 73, 0.0337375) !important;
}

.owl-nav{
  .owl-prev,
  .owl-next{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px !important;
    z-index: 10;
    span{
      font-size: 60px;
      color: #26273B;
    }
    &:hover{
      background: transparent !important;
      border: none;
    }
    &:focus{
      outline: none;
    }
  }
  .owl-prev{
    left: -25px;
  }
  .owl-next{
    right: -25px;
  }
}

.owl-carousel {
  .owl-nav {
    position: absolute;
    width: 200px;
    margin: 0;
    bottom: 80px;
    .owl-prev {
      left: 0;
      span {
        &:after {
          content: "";
          width: 45px;
          position: absolute;
          border-bottom: solid 1px #fff;
          bottom: -10px;
          right: 16px;
          overflow: hidden;
          left: inherit;
          transform: rotate(-45deg);
        }
      }
    }
    .owl-next {
      right: 0;
      span {
        &:before {
          content: "";
          width: 45px;
          position: absolute;
          border-bottom: solid 1px #fff;
          bottom: -10px;
          left: 16px;
          overflow: hidden;
          right: inherit;
          transform: rotate(45deg);
        }
      }
    }
    .owl-prev, .owl-next {
      span {
        border-bottom: solid 1px #fff;
        width: 30px;
        display: inline-block;
        text-indent: -90px;
        overflow: hidden;
        position: relative;
        font-size: 0;
        line-height: 30px;
        &:hover, &:active, &:focus {
          border-color: $primary;
          &:before {
            border-color: $primary;
          }
        }
      }
    }
  }
  .owl-dots {
    display: block;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 200px;
    span {
      background: none !important;
      border: solid 1px $primary;
      margin: 5px !important;
      height: 10px !important;
      width: 10px !important;
    }
    .active {
      span {
        background: #fff !important;
        border-color: transparent;
        height: 13px!important;
        width: 13px!important;
      }
    }
  }
}
.home-nav-logo {
  width: 205px;
}
.sec-hero {
  min-height: 500px;
  background-size: cover;
  background-position: bottom;
  position: relative;
  width: 100%;
  height: 900px;
  background: #0a1c26;
  padding-top: 10rem;

  @media (max-width: 1400px) {
    height: 800px;
  }

  @media (max-width: 1200px) and (min-width: 1024px){
    height: 1000px;
  }


  @media (max-width: 1023px) {
    padding-top: 120px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-origin: top right;
    transform: $n-skewY;
  }
  .slider {
    width: 100%;
    height: 100%;
    .owl-carousel, .owl-stage-outer {
      height: 100%;
    }
    .slides {
      // padding-bottom: 16rem;
      height: 100%;
      .slid-img {
        position: absolute;
        right: 0px;
        top: 40px;
        max-width: calc(100vw - 740px);

        @media (max-width: 1300px) {
          max-width: calc(100% - 600px);
          top: 140px;
        }

        @media (max-width: 1200px) {
          position: relative;
          top: -140px;
          max-width: 60%;
          margin-left: auto;
        }

        @media (max-width: 767px) {
          top: 40px;
          max-width: 100%;
        }
      }
    }
    @media (max-width:$mobile) {
      .owl-nav {
        display: none;
      }
      .owl-dots {
        left: 0;
        right: 0;
      }
    }
  }
  .owl-nav {
    left: 20%;
  }
  .owl-dots {
    left: 24%;
  }
}

.eliminate-complexity {
  background: $primary;
  // height: 600px;
  position: relative;
  z-index: 1;
  &-icon {
    background: #fff;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .helping-image {
    right: 0;
    top: -180px;
  }
  .helping-image-lg-polyon {
    bottom: -170px;
  }

  &-box {
    @media (max-width: $desktop) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.context {
  background: $secondary-color;
  .helping-image {
    bottom: 0;
    left: 70px;

    @media (max-width: $desktop) {
      bottom: -70px;
    }
  }

  .owl-carousel {
    .owl-nav {
      @media (max-width: $desktop) {
        bottom: -20px;
      }
    }
    .owl-dots {
      @media (max-width: $desktop) {
        bottom: -48px;
      }
    }
  } 
}
.add-bg {
  background: url('../assets/images/slider-bg.png') no-repeat 93% 50px;
  
}
.what-idea {
  background: none;
  .helping-image {
    right: 180px;
    top: -136px;
  }
}
.software-services {
  background: url('../assets/images/bg-image.png') no-repeat;
  background-size: cover;
  .items, .owl-item {
    height: 100%;
  }
  &-box {
    background: #fff;
    padding: 32px;
    border-radius: 5px;
    height: 100%;
    p {
      color: rgba($text-black, 0.5) ;
      min-height: 170px;
    }

    @media (max-width: $desktop) and (min-width: $mobile) {
      min-height: 410px;
    }
  }
  &-icon {
    img {
      width: auto !important;
    }
  }
  .owl-carousel {
    .owl-nav, .owl-dots {
      margin: auto;
      left: 0;
      right: 0;
    }
    .owl-nav {
      bottom: 0;
    }
    .owl-dots {
      bottom: -28px;
    }
  }
}
.scaling-process {
  background: url('../assets/images/bg-pattern.png') no-repeat 110% 155%;
  .helping-image {
    top: -170px;
    right: 50px;
  }
  .process-section {
    position: relative;
    &:after {
      content: "";
      background: url('../assets/images/arrow-sign.png') no-repeat 0px 63%;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:before {
      content: "";
      background: url('../assets/images/arrow-sign.png') no-repeat 0px 10%;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: rotateY(180deg);
    }
  }
  .process {
    &-icon {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-details {
      width: calc(100% - 120px);
      margin-left: 20px;
    }
    &-number {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      border: solid 1px $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: rgba($text-black, 0.5);
    }
  }
  .arrow-left {
    img {
      transform: rotate(180deg);
    }
  } 
}
.connecting {
  background: $primary;
  .association {
    background: #0A1C26;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }
  .owl-nav {
    left: 44%;
    bottom: 0px;
  }
  .owl-dots {
    left: 50%;
    bottom: -30px;
    span {
      border: solid 1px #fff;
    }
  }
  .video-play-action a {
    background: #fff;
    animation: pulseWhite 2s infinite;
    &:after {
      border-left: 15px solid $alternate-color;
    }
  }
}
.define-community {
  position: relative;
  &:before {
    content: "";
    background: $primary;
    position: absolute;
    width: 100%;
    height: 120px;
    top: -1px;
    transform: skewY(5deg);
  }
   .community-slide {
    position: relative;
    &-inner {
      position: absolute;
      z-index: 1;
      top: 40%;
      transform: translateY(-40%);
      left: 10%;
    }
  }
  .owl-dots {
    top: 60%;
    left: 11%;
  }
  .owl-nav {
    top: 60%;
    left: 11%;
    bottom: inherit;
  }

  @media (max-width: $desktop) {
    .owl-dots,
    .owl-nav {
      top: auto;
      bottom: 120px;
    }

    .owl-dots {
      margin-bottom: -28px;
    }
  }
}
.digital-house {
  background: $secondary-color;
  margin-top: -200px;
  position: relative;
  .text-light-50 {
    color: rgba(255,255,255,0.5);
  }
  .team {
    background:   106%;
  }
  .helping-image-left {
    left: -145px;
    bottom: 220px;
    transform: rotate(85deg);
  }
  .helping-image-right {
    right: 0;
    bottom: 260px;
  }
}
.own-digital {
  position: relative;
  width: 100%;
  background: $primary url('../assets/images/light-bg.png') no-repeat -35px 50%;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-origin: top left;
    transform: $n-skewY;
  }
  &-inner {
    position: relative;
    z-index: 1;
  }
  p {
    color: rgba(255,255,255,0.7);
  }
}
.sh-footer {
  background: $secondary-color;
  .list-group-horizontal {
    .list-group-item {
      padding: 0;
      height: 58px;
      width: 58px;
      margin: 30px 15px 0 0;
      a {
        background: rgba(196,196,196,0.1);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  .list-group-item {
    background: none;
    border: 0px;
    a {
      color: #fff;
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .back-top {
    padding: 14px 33px;
    border: 1px solid rgba(95, 193, 237, 0.3);
    color: #fff;
    border-radius: 5px;
  }
}
.hero-text {
  margin-top: 150px;
  height: 100%;
  @media (max-width: $desktop) {
    margin-top: 50px;
  }
}

.line-divider {
  max-width: 500px;
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1190px;
  }
}

.btn-getstarted {
  background: none;

  &:hover {
    background-color: transparent !important;
  }
}
.shar-navbar {
  background: none;
  box-shadow: none;
}

html body {
  .shar-navbar-links,
  .actions.d-flex.flex-column,
  .slid-content,
  .slide-text,
  .skewY,
  .digital-house,
  .own-digital {
    .btn-primary {
      border-radius: 2px;
      color: white;
      border: solid 1px rgba(95, 193, 237, 0.3);
      font-size: 14px !important;
      font-weight: normal;
      letter-spacing: 1px;
      width: 140px !important;
      transition: 0.3s ease-in-out;
      &::after {
        content: '';
        background: linear-gradient(180deg, lighten($primary,24.21%) 15%, $primary 100%) !important;
        opacity: 0;
        transition: 0.3s linear opacity;
        transform: none !important;

      }

      &:not(.dropdown-toggle) {
        &:hover,
        &:active,
        &:focus {
          color: $white !important;
          
          &::after {
            opacity: 1;
          }
        }
      }
    }
    .btn-alternate {
      background: $alternate-color !important;
      padding: 18px 35px;
      letter-spacing: 1px;
      border-radius: 2px;
      min-height: 66px;

      &::after {
        content: '';
        background: linear-gradient(180deg, lighten($alternate-color,24.21%) 15%, $alternate-color 100%) !important;
        opacity: 0;
        transition: 0.3s linear opacity;
        transform: none !important;
      }

      &:hover,
      &:active,
      &:focus {
        color: $white !important;
        border-color: $alternate-color !important;
        
        &::after {
          opacity: 1;
        }

        img {
          filter: brightness(0);
        }
      }

      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.video-play {
  margin: 2rem 0 2rem 1rem;
  &-action {
    a {
      height: 45px;
      width: 45px;
      background: #00B0FF;
      display: inline-block;
      border-radius: 50%;
      position: relative;
      animation: pulse 2s infinite;
      box-shadow: 0 0 0 rgba(0, 36, 203, 0.9);
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 15px solid #fff;
        border-bottom: 10px solid transparent;
        left: 17px;
        top: 12px;
      }
    }
  }
  &-text {
    text-transform: uppercase;
    margin-left: 1.5rem;
    letter-spacing: 1px;
    font-weight: 500;
  }
}

.modal {
  padding-left: 0 !important;
  .modal-content {
    background: #fff url('../assets/images/modal-bg.png') no-repeat bottom left;
  }
  .modal-body {
    padding: 5rem 1rem;
  }
  #registration {
    .modal-title {
      position: relative;
      color: #031520;
      &:after {
        content: "";
        height: 2px;
        width: 70px;
        position: absolute;
        background: $alternate-color;
        bottom: -10px;
        left: 0;
      }
    }
    p {
      color:#879095;
      font-weight: 500;
      font-size: 16px;
    }
    .form-group {
      width: 65%;
      position: relative;
      .icon {
        position: absolute;
        width: 50px;
        height: 50px;
        align-items: center;
        display: flex;
      }
      .form-control {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding: 0.375rem 0.75rem 0.375rem 2rem;
      }
      .icon-btn {
        min-width: auto;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        height: 50px;
        width: 50px;
      }
    }
    .helping-image {
      right: 0;
    }
    .helping-image-bottom {
      right: 120px;
      bottom: -180px;
    }
    .login-btn {
      background: no-repeat;
      border: 0;
      color: #72767C;
      text-transform: uppercase;
      margin-top: 1rem;
    }
  }
}
.services {
  p {
    color: #212529 !important;
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
      &:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em; 
      }
      li {
        margin: 0;
        &:before {
          content: counters(item, ".") " ";
        }
      }
    }
  }
  .level > {
    li {
      padding-left: 25px;
     
        &:before {
          display: none;
        }
      
    }
  }
}
.welcome-page {
  background: #0e202c url('../assets/images/welcome-bg.png') no-repeat top left;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  &:after{
    width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 10%;
    transform: skewY(-5deg);
    background-color: #fff;
    z-index: 2;
  }
  &-inner {
    position: relative;
    z-index: 5;
    padding: 0 0 10% 0;
    background: url('../assets/images/logos/s-logo-bg.png') no-repeat bottom left;
    top: 150px;
    margin-bottom: 150px;
  }
  .title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 70px;
      background: $alternate-color;
      left: 0;
      bottom: 0;
    }
  }
  .owl-carousel {
    .owl-dots {
      bottom: -30px;
      left: 30%;
      .active {
        span {
          background: $primary !important;
        }
      }
    }
    .owl-nav {
      bottom: 0;
      left: 30%;
      .owl-prev, .owl-next {
        span {
          border-bottom: solid 1px #0a1c26;
        }
      }
      .owl-prev {
        span {
          &:after {
            border-bottom: solid 1px #0a1c26;
          }
        }
      }
      .owl-next {
        span {
          &:before {
            border-bottom: solid 1px #0a1c26;
          }
        }
      }
    }
  }
  .owl-stage-outer {
    overflow: inherit;
  }
  .bottom-helping-image {
    position: absolute;
    bottom: 0;
    right: -150px;
    img {
      width: auto;
    }
  }
  .top-helping-image {
    img {
      width: auto;
    }
    .first-image {
      position: absolute;
      top: -120px;
      left: 40px;
    }
    .second-image {
      left: -33px;
      position: absolute;
      top: -55px;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 176, 255, 0.9);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(0, 176, 255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 176, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 176, 255, 0.9);
    box-shadow: 0 0 0 0 rgba(0, 176, 255, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(0, 176, 255, 0);
      box-shadow: 0 0 0 10px rgba(0, 176, 255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 176, 255, 0);
      box-shadow: 0 0 0 0 rgba(0, 176, 255, 0);
  }
}
@-webkit-keyframes pulseWhite {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulseWhite {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@media (max-width:$mobile) {
  .sec-hero {
    padding-top: 5rem;
  }
  br, .process-section:after, .process-section:before, .top-helping-image, .bottom-helping-image {
    display: none;
  }
  .welcome-page-inner {
    background: none;
  }
  .welcome-page .owl-carousel .owl-nav {
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80%;
    right: 0;
  }
  .line-height-60 {
      line-height: normal;
  }
  .font-18 {
    font-size: 16px;
  }
  .font-46, .font-48, .font-50  {
    font-size: 34px;
  }
  .context {
    .owl-nav {
      display: none;
    }
    .owl-dots {
      bottom: -50px;
      left: 0;
      width: 100%;
    }
  }
  .software-services {
    .owl-nav {
      display: none;
    }
  }
  .add-bg {
    background: none;
  }
  .btn-alternate {
    font-size: 14px;
  }
  .arrow-right {
    transform: rotate(90deg);
  }
  .community-slide-img img {
    width: auto !important;
    max-width: inherit;
  }
  .connecting {
    .owl-nav {
      display: none;
    }
    .owl-dots {
      left: 0;
      bottom: -30px;
      width: 100%;
    }
  }
  .define-community {
    .community-slide-img {
      display: none;
    }
    .community-slide-inner {
      position: static;
      transform: inherit;
      .text-light-blue {
        line-height: normal;
      }
    }
    .community-slide {
      padding: 5rem 0;
    }
    .slide-1, .slide-2, .slide-3, .slide-4, .slide-5, .slide-6 {
      background-size: cover;
      min-height: 575px;
    }
    .slide-1 {
      background: url('../assets/images/community-image-1.png') no-repeat;
    }
    .slide-2 {
      background: url('../assets/images/community-image-2.png') no-repeat;
    }
    .slide-3 {
      background: url('../assets/images/community-image-3.png') no-repeat;
    }
    .slide-4 {
      background: url('../assets/images/community-image-4.png') no-repeat;
    }
    .slide-5 {
      background: url('../assets/images/community-image-5.png') no-repeat;
    }
    .slide-6 {
      background: url('../assets/images/community-image-6.png') no-repeat;
    }
  }
  .modal .modal-body {
    padding: 1rem;
    .form-group {
      width: 100% !important;
    } 
    .font-60 {
      font-size: 46px;
    }
  }
}

@media (min-width: $hd-tab) {
  .modal-lg, .modal-xl {
    max-width: 100%;
  }
}

.sm-login-modal {
    .form-group {
      input {
        color: #000;
        font-size: 16px;
        font-weight: 500;

        &::placeholder {
          color: #000;
          font-family: 'Inter', sans-serif !important;
          font-size: 16px;
        }
      }
    }

    @extend .scroll-style;
}

.modal-close-btn {
  position: absolute;
  right: 30px;  
  top: 30px;
  z-index: 10;

  &:not(.cursor-pointer) {
    pointer-events: none;
  }

  + .close {
      margin-top: 0px;
      margin-right: 3px;
      
      span {
        opacity: 0;
      }
  }
}

.agreement-label {
  color: #879095;
  font-weight: 500;
}

.sm-show-icon {
  width: 20px;
  position: relative;
  top: 6px;
  fill: #b2b3b8;
  transition: 0.3s ease-in-out;

  &-active {
    fill: $primary;
  }
}

.slid-content {
  @media (max-width: 1440px) and (min-width: 575px) {
    h1.font-60.b-700.line-height-60 {
      font-size: 50px;
      line-height: 1.2;
    }
  }
}

.sm-login-modal {
  padding: 35px 0;

  .modal-dialog {
    margin: 0 auto;
    overflow: hidden;
  }
}

.grid-animation {
    position: absolute;
    top: 0;
    transform: scale(1.15);
    animation: grid-rotate 60s linear infinite;
}

.persons-animation {
  position: absolute;
  top: 0;
  transform: scale(0.7) translateY(80px) translateX(-20px);
  animation: persons-animation 1s ease-in-out 1s forwards;
  opacity: 0;
}

.s-logo-animation {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 90px);
  transform: translateY(-50%);
  max-width: 270px;

  @media (max-width: 1400px) {
    max-width: 210px;
    left: calc(50% - 70px);
  }

  @media (max-width: 1200px) {
    max-width: 160px;
    left: calc(50% - 50px);
  }
}

@keyframes grid-rotate {
  0% {
    transform: scale(1.15) rotate(0);
  }
  100% {
    transform: scale(1.15) rotate(360deg);
  }
}

@keyframes persons-animation {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(100px) translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: scale(0.7) translateY(80px) translateX(-20px);
  }
 }

 html,body {
   scroll-behavior: smooth;
   @extend .scroll-style;
 }

.scroll-style {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #cccccc;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $alternate-color !important;
    cursor: pointer;
  }
}

@media not all and (min-resolution:.001dpcm) {
  body .btn.btn-alternate::after,
  body .btn.btn-primary::after {
    transition: none !important;
  }
}

.no-transition-after {
  &:after {
    transition: none !important;
  }
}

.new-shar-header {
  @media (max-width: 992px) {
    .shar-navbar {
      .home-nav-logo {
        margin-left: 0;
      }

      .navbar-toggler {
        left: auto;
        right: 0;
      }

      .navbar-outer {
        left: -20px;
        right: -20px;
        width: auto
      }
    }
  }
}

.mine {
  background: #000;
}

.video-fit-screen-sm {
  video {
    height: calc(100vh - 10px) !important;
    margin-top: 8px;
  }
}

.landing-page-video-close-sm {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}


.btn {
  &.btn-theme {
    width: $theme-btn-width;
    height: $theme-btn-height;
    min-width: $theme-btn-width;
    min-height: $theme-btn-height;
    font-size: $theme-btn-font !important;
    font-weight: $theme-btn-weight;
    letter-spacing: $btn-lg-letter-spacing;
  }

  &.btn-primary:not(.btn-get-started):not(.dropdown-toggle) {
    &::after {
      content: '';
      background: linear-gradient(180deg, lighten($primary,24.21%) 15%, $primary 100%) !important;
      opacity: 0;
      transition: 0.3s linear opacity;
      transform: none !important;

    }
    &:hover,
    &:active,
    &:focus {
      color: $white !important;
      border-color: $primary !important;

      &::after {
        opacity: 1;
      }
    }
  }

  &.btn-alternate,
  &.btn-alternate-outlined {
    background: $alternate-color;
    &::after {
      content: '';
      background: linear-gradient(180deg, lighten($alternate-color,24.21%) 15%, $alternate-color 100%) !important;
      opacity: 0;
      transition: 0.3s linear opacity;
      transform: none !important;

    }
    &:hover,
    &:active,
    &:focus {
      color: $white !important;
      border-color: $alternate-color !important;

      &::after {
        opacity: 1;
      }
    }
  }

  &.btn-alternate-outlined {
    background-color: transparent;
    border-width: 1px;
  }
}

body {
  .form-control {
    &::placeholder {
      font-family: $font-placeholder;
      color: #344356;
    }
  }
}

.btn-primary.btn-get-started {
  background: $alternate-color;
  border-radius: 3px !important;
  border-color: $alternate-color;
  height: auto;
  top: 0;
}

.btn.btn-primary.btn-get-started {
  letter-spacing: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  .client-profile-inner > & {
    top: -3px;
    right: 60px;
  }

  &::after {
    content: '';
    background: linear-gradient(180deg, lighten($alternate-color,24.21%) 15%, $alternate-color 100%) !important;
    opacity: 0;
    transition: 0.3s linear opacity;
    transform: none !important;

  }
  &:hover,
  &:active,
  &:focus {
    color: $white !important;
    border-color: $alternate-color !important;

    &::after {
      opacity: 1;
    }
  }
}

@media (max-width: $hd-tab) {
  body {
    .header {
      border-radius: 0;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);

      &-logo {
        width: 30px;
        height: 33px;
        object-fit: contain;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='33' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M17.434 19.331c-.44-.627-.955-1.189-1.535-1.657l-2.031-1.516-5.541-3.678a4.41 4.41 0 0 1-1.348-1.282c-.328-.468-.487-1.114-.468-1.984-.047-.721.281-1.395.889-1.797.58-.421 1.301-.627 2.031-.608 1.095 0 1.938.374 2.518 1.142.58.749.889 2.003.889 3.753v.328h5.195v-.908c0-2.892-.721-5.054-2.19-6.496s-3.566-2.19-6.29-2.19c-1.142 0-2.284.159-3.398.487-.983.3-1.891.796-2.677 1.441a6.44 6.44 0 0 0-1.75 2.331 7.75 7.75 0 0 0-.608 3.145c0 3.079 1.423 5.522 4.287 7.357l4.82 3.164c.842.515 1.61 1.189 2.237 1.938.487.674.768 1.488.749 2.331a3.31 3.31 0 0 1-.842 2.284c-.561.627-1.348.936-2.377.936a5.49 5.49 0 0 1-1.516-.206c-.487-.14-.908-.421-1.254-.796-.393-.44-.702-.983-.861-1.535-.253-.814-.346-1.657-.328-2.49v-1.114H.838v1.114c0 2.958.768 5.26 2.303 6.917S6.96 32.21 9.992 32.21c2.583 0 4.614-.58 6.084-1.722a4.07 4.07 0 0 1 2.658-5.101c.066-.393.084-.814.084-1.207a10.25 10.25 0 0 0-.328-2.771c-.29-.721-.608-1.423-1.058-2.078zM28.993 5.516c1.189-4.24-4.521-7.104-7.619-4.081-1.03.983-1.254 2.05-1.282 3.239v18.57c.094.702.702 1.301 1.47.889.346-.187.44-.534.58-.861l6.852-17.756z' fill='%23000'/%3E%3Cpath d='M20.485 26.576c-1.488-.328-2.986.655-3.285 2.143-.328 1.488.655 2.986 2.143 3.285 1.488.328 2.986-.655 3.285-2.143.328-1.488-.627-2.958-2.143-3.285z' fill='%2308c'/%3E%3C/svg%3E");
        margin-right: auto;

        img {
          display: none;
        }
      }
    }
  }
}

.home-page-options {
  @media (max-width: $hd-tab) {
    .dropdown {
      width: 100%;

      .btn-support-team {
          width: 100%;
      }
    }

    .ml-auto.d-flex {
      display: none !important;
    }
  }
}

.welcome-card-sharemeier {
  .welcome-card {
    border-radius: 2px;
    flex-wrap: wrap;
    max-width: 100%;
    margin-right: 0;
    height: auto;

    &-text,
    .chip-sm {
      display: none;
    }

    &-heading {
      position: absolute;
      left: -42px;
      top: 0;
      text-transform: capitalize;

      span.display-7 {
        font-size: 20px !important;
      }
    }

    &-img {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      border-radius: 2px 2px 0 0;
      box-shadow: none;
      height: 350px;
    }
    
    &-section {
      padding: 0;
      box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
    }

    &-body {
      position: relative;

      a {
        margin-top: 38px;
      }
    }
  }

  .p-lg-4.p-2.d-flex.w-100 {
    padding: 16px !important;
    a {
      margin-top: 60px;

      img {
        width: 30px;
        height: 30px;
        min-width: 30px;
      }
    }
  }
  .show-in-sm {
    display: block !important;
  }
}

.support-team-tab {
  @media (max-width: $mobile) {
    > .dropdown {
      width: 100%;
      
      .btn {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: $mobile) {
    + .mt-4.pt-lg-2 .new-follower-card {
      margin: 0 auto 20px !important;
    }
  }
}

.city-details {
  @media (max-width: $mobile) {
    .border-right  {
      border: none !important;
    }

    .overflow-hidden.col-md-9 {
      .col-md-3:last-child {
        margin-bottom: 60px;
      }
    }
  }
}

.post-pic {
  background: linear-gradient(180deg, lighten($primary, 10%) 0%, $primary 100%) !important;
  box-shadow: inset 0 0 0 6px $primary;

  svg path {
    fill: #fff;
  }
}

.city-account-summary {
  @media (max-width: $hd-tab) {
    th:nth-child(3) {
      min-width: 160px;
    }
  }
}

.post-uplodad-label .add-icon-upper {
  background-color: $primary;
}