.tool-container {
  width: 49px;
  height: 49px;
  border-radius: 10px;
  background-color: $primary;
}

.mgs-header-padding {
  @include padding(10px, 38px,10px,32px);

  @media (max-width: $mobile) {
    @include padding(10px, 20px,10px,20px);
  }

  a:hover {
    @extend .color-primary;
  }
}

.chat-details {
  .text-time {
    color: #A0A0A0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  .input-group-text {
    @include padding (.375rem, .95rem, .375rem, .95rem);
    border: 2px solid $border-color;
    border-right: 0;
    background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(251, 251, 251));
  }

  .send-icons-position {
    padding-left: 8px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 5;
  }

  .input-msg-box {
    width: calc(100% - 48px);
    position: absolute;
    bottom: 20px;
    left: 24px;

    @media (max-width: $hd-tab) {
      width: calc(100% - 32px);
    }

    .input-group {
      border: 1px solid $search-border;
      border-radius: $input-border-radius;
      box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
      background: white;
      padding: 9px 0;

      .form-control {
        margin-bottom: 0 !important;
        box-shadow: none;
      }
    }

    textarea[autoresize="true"] {
      height: 29px;
      resize: none;
      padding: 0px 20px;
      max-height: 150px;
      border-radius: 35px;
      max-width: calc(100% - 45px);
      border: none;
      background: transparent;

      &::-webkit-scrollbar {
        width: 5px;
      }

      @media (max-width: $mobile) {
        padding: 0 0 0 20px;
      }
    }
  }
}

@media (max-width: $hd-tab) {
  .display-web {
    display: none;
  }
}

.direct-message-container {
  @media (max-width: $hd-tab) {
    position: absolute;
    z-index: 10;
    background: white;
    animation: slideLeft 0.3s ease-in-out;
  }
}

.message-box-view {
  height: calc(100vh - 240px);
}

.chat-activator {
  width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    cursor: pointer;

    svg {
      transform: rotate(180deg) translateX(-1px);
      width: 8px;
    }
}

.btn-table-dropdown {
  font-family: $font-family-base;
  background: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: 1px solid #ccc !important;
  margin-right: 10px;
  min-height: 33px;
  height: 33px;
  min-width: 160px;

  &:after {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  svg {
    width: 10px;

    g {
      fill: #373838;
    }
  }

  + .dropdown-menu {
      margin-top: -3px;
      border-radius: 0 0 4px 4px;
      border-top: none;
      font-size: 14px;
      padding-top: 0;

      .dropdown-item {
        padding-left: 14px;
      }
  }
}

.action-td-chat {
  width: 200px;
}

.td-username {
  min-width: 240px;
}

.td-timestamp {
  width: 150px;
}

.fill-available {
  .table-responsive {
    min-height: 200px;
  }
}

.unread {
  background-color: #F6F6F6 !important;
}

.read {
  background-color: #FFFFFF !important;
}

.msg-unread {
  background-color: $hover-light-primary !important;
}

#messageList.pl-md-3 {
  > .mt-4 {
    &:empty {
      display: none;
    }
  }
}
