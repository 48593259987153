.sub-domain {

  &-conatainer {
    max-width: 800px;
    min-height: calc(100vh - 70px);

    @media (max-width: $mobile) {
      min-height: auto;
    }
  }

  &-logo {
    max-width: 275px;
    height: auto;
    object-fit: contain;
  }

  &-feature-list {

    li {
      position: relative;

      &:before {
        content: '';
        background: url('../assets/images/icons/tick.png');
        background-size: 17px 15px;
        display: inline-block;
        position: absolute;
        height: 15px;
        width: 17px;
        left: -30px;
        top: 8px;

        @media (max-width: $tab) {
          background-size: 15px 10px;
          position: absolute;
          height: 10px;
          width: 15px;
          left: -23px;
        }
      }
    }
  }

  &-footer-logo {
    width: 24px;
  }

  &-collapsed {
    background: transparent;
    border: none !important;
    background: transparent !important;

    &:after {
      display: none;
    }

    &-logo {
      width: 35px;
    }
  }

}
