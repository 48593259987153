.direct-message {
  ul li a {
    color: $primary-color;
  }

  .close-icon-width {
    width: 13px;
  }

  .close-chip{
    top: 3px;
    left: 3px;
  }

  .grey-close-chip {
    border-radius: .25rem;
    background-color: $chip-bg-grey;
  }
  .padding-y-sm {
    @include padding(3px ,0px,3px,0px);
    height: 35px;
    padding: 0 5px;
  }

  #friend_search:disabled {
    background-color: white;
  }
}

.search-list {
  max-width: 471px;
}
