$primary: #0988CC; // Please also change primary color in config.js
$secondary-color: #0a1c26;
$alternate-color: #E49343;
$border-focus: #2cc3f1;
$hover-light-primary: #caf0fa;
$profile-upload-bg: #dcf6fe;
$profile-upload-border: #FF961E;
$profile-border: rgba(255, 150, 30, 0);
$black: rgb(0,0,0);
$white: rgb(255,255,255);
$bg-header : #FAFAFA;
$shadow-grey: #f1f0f0;
$light-grey: #a2a0a0;
$text-grey: #929191;
$text-black: #000000;
$icon-active :#87BF40;
$color-warning: #F08C21;
$bg-grey: #919191;
$bg-red: #DD2741;
$bg-blue: #4A7FD0;
$primary-color: #3D3C40;
$light-black: #606060;
$camera-icon: #1A4445;
$border-color: #cacaca;
$chip-bg-grey: #E7E7E7;
$text-black-muted: #444;
$notification-color: $alternate-color;

$table-row-bg-color:#F6F6F6;
$table-td-border-color:#d6d6d6;
$card-bg-white: #FDFDFD;
$search-border: #E6E6E6;
$bg-light-grey: #F5F5F5;
$color-blue: #216eb6;
$chips-light: #F7F7F7;
$radio-inactive: #939393;
$stars-color: #FAB12C;

$font-family-base: 'Inter_reg', sans-serif;
$font-strongbold: 'Inter_black';
$font-bold: 'Inter_bold';
$font-italic-bold: 'Inter_bold_italic';
$font-medium: 'Inter_med';
$font-light: 'Inter_light';
$font-thin: 'Inter_thin_extra';
$font-regular-italic: 'Inter_italic';
$font-light-italic: 'Inter_light_italic';
$font-semi-bold: 'Inter_semi_bold';
$font-med-italic: 'Inter_med_italic';
$font-placeholder: $font-medium;

//media query breakpoints
$mobile: 575px;
$tab: 768px;
$hd-tab: 992px;
$fhd-tab: 1024px;
$desktop: 1200px;
$hd-desktop: 1440px;
$desktop-lg: 1300px;

$btn-lg-size: 16px;
$btn-lg-height: 58px;
$btn-lg-letter-spacing: 1px;
$theme-btn-width: 177px;
$theme-btn-height: 66px;
$theme-btn-font: 16px;
$theme-btn-weight: 700;

$phoneCountry-margin: 3px;
$input-border-radius: 15px;
$input-border-color: #cacaca;
$input-box-shadow: 0 0 0 0 rgba(60, 127, 209, 0);
$input-height: 70px;
$input-text: 18px;
$input-placeholder-text: 16px;
$input-padding-x: 1.25rem;
$input-padding-y: 0.375rem;
$input-phone-padding: $input-padding-x;

$btn-border-radius: 2px;
$btn-text-transform: capitalize;
$btn-box-shadow: none;

$form-bottom-margin: 16px;
$padding-country-code: 6px 12px;

//changing in PWA
$pwa-input-border-radius: 15px;
$pwa-input-border-color: #cacaca;
$pwa-input-box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
$pwa-input-height: 70px;
$pwa-input-text: 18px;
$pwa-input-placeholder-text: 16px;
$pwa-input-padding-x: 1.25rem;
$pwa-input-padding-y: 0.375rem;

$pwa-btn-border-radius: 2px;
$pwa-btn-text-transform: capitalize;
$pwa-btn-box-shadow: none;

$pwa-form-bottom-margin: 16px;
$pwa-padding-country-code: 6px 12px;
$pwa-sub-cat-margin: 0;
$pwa-error-margin: 5px;

$pwa-select-arrow-position: calc(100% - 20px) calc(1em + 2px),calc(100% - 15px) calc(1em + 2px),calc(100% - .5em) .5em;
$pwa-select-arrow-size: 12px;

$pwa-web-placeholder-text: 18px;

$nav-item-border-color: #C6C5DB;

$light-grey-bg:#DBDBDB;

$sh-blue:#d9edf6;
$sh-text-dark:#4b4b4b;
