
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
	-o-transition: $property $duration $easing;
}

@mixin circle($width, $height, $line-height,$border-radius:50%) {
  width: $width;
  height:$height;
  line-height: $line-height;
  border-radius: 50%;
}

@mixin transform($transforms) {
	-o-transform: $transforms;
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
	@include transform(scale($scale));
}

@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin ($origin) {
	-o-transform: $origin;
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	left:0;
	top:0;
	z-index: 2;
}

@mixin animation($animation) {
	-o-animation: $animation;
	-moz-animation: $animation;
	-webkit-animation: $animation;
	animation: $animation;
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin hoverEffectOutlined($colorBg,$colorText,$origin) {
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $colorBg;
    transition: 0.2s ease-in-out;
    transform: scaleY(0);
    transform-origin: $origin;
    z-index: -1;
  }

  &:hover{
    color: $colorText;
    &:after{
      transform: scaleY(1);
    }
  }

}
@mixin hoverEffectFilled($colorBg,$colorText,$origin) {

  position: relative;
  z-index: 1;
  overflow: hidden;
  
  &:after{
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);;
    left: -1px;
    top: -1px;
    background: $colorBg;
    transition: transform 0.2s ease-in-out;
    transform: scaleY(0);
    transform-origin: $origin;
    z-index: -1;
    // border-radius: $btn-border-radius;
    // border: 1px solid $colorText;
  }

  @media (min-width: $hd-tab) {
    &:hover{
      color: $colorText !important;
      border: 1px solid $colorText;
      &:after{
        transform: scaleY(1);
      }
    }
  }

  &:focus{
    color: $colorText !important;
    border: 1px solid $colorText;
    &:after{
      transform: scaleY(1);
    }
  }
}
