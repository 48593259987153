.market-place {
  &-slider {
    .carousel-indicators {
      display: none;
    }
    .carousel-item {
      max-width: 400px;
    }
  }

  &-banner {
    border-radius: 4px;
    background: red !important;
  }

  &-add-btn {
    min-width: auto;
    border-radius: 8px;
    padding: 0px 25px;
    border: 1px solid !important;

    &:after {
      border-radius: 8px;
    }
  }

  &-apps {
    max-height: calc(100vh - 400px);

    @media (max-width: $hd-tab) {
      max-height: unset;
    }
  }

  &-img {
    @media (max-width: $desktop) {
      width: 64px;
      height: 64px;
    }
  }
}

.shar-product-ratings {
  color: $stars-color;
}

.tool-img-container {
  img {
    width: 250px;
    height: 270px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.tool-description-row {
  min-height: calc(100% - 70px);
}

.tool-btn-container {
  position: absolute;
  bottom: 0;

  @media (max-width: $mobile) {
    position: relative;
  }
}

.tool-chat .input-msg-box {
  bottom: 0;
}
