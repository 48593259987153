.article-expandable-options {
    background: #fff;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
    margin-left: auto;
    width: 50px;
    position: fixed;
    top: 58px;
    right: 0;
    bottom: 0;
    transition: 0.3s ease-in-out;
    z-index: 10;

    .pill-option {
        color: $primary;
    }

    .pill-expand {
        svg {
            transform: translateX(-6px);
        }

        @media (max-width: $hd-tab) {
            display: none !important;
        }
    }

    &.options-opened {
        width: 500px;

        .icons-list {
            display: flex;
            overflow: auto;
            padding: 8px 12px 10px 12px;
            @extend .scroll-y;
            
            @media (max-width: $hd-tab) {
                flex-wrap: wrap;
            }
            
            @media (min-width: $hd-tab) {
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
            }

            .pill-expand {
                margin-right: 10px;
                svg {
                    min-width: 25px;
                    transform: rotate(180deg);
                }

                @media (max-width: $hd-tab) {
                    display: inline-block !important;
                    width: 60px;
                    height: 5px;
                    background: #d8d8d8;
                    border-radius: 3px;
                    margin: auto;
                    margin-bottom: 24px;
                    margin-top: 10px;

                    svg {
                        display: none;
                    }
                }
            }

            
            .pill-option {
                width: auto;
                background: linear-gradient(131.62deg, rgba(lighten($primary,5%),0.1) -6.12%, rgba(darken($primary,5%),0.1) 82.96%);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                border-radius: 15px;
                margin-right: 10px;
                padding: 10px 16px;
                white-space: nowrap;

                @media (max-width: $hd-tab) {
                    &:not(.active) {
                        display: none;
                    }
                }

                &.active {
                    background: linear-gradient(131.62deg, lighten($primary,10%) -6.12%, $primary 82.96%);
                    box-shadow: 0px 2px 4px rgba(0, 0, 0,0.5);
                    color: #fff;
                    @media (max-width: $hd-tab) {
                        background: transparent;
                        box-shadow: none;
                        width: 100%;
                        padding: 0;
                        height: auto;
                        color: #000;
                        
                        svg {
                            display: none;
                        }
                    }
                }

                .option-name {
                    display: inline-block;
                    font-size: 18px !important;
                }
            }
        }

        .article-expandable-body {
            display: block;

            .article-page-side {
                &:not(.cs-page-side) .article-page-side-head {
                    display: none !important;
                }

                &.cs-page-side .article-page-side-head {
                    border-bottom: none !important;

                    + .py-3.px-4 {
                        @media (min-width: $hd-tab) { 
                            height: calc(100vh - 200px);
                        }
                    }

                    @media (max-width: $hd-tab) {
                        padding: 0 !important;

                        span.display-8.font-bold.ml-2 {
                            display: none !important;
                        }

                        .cs-lesson-indicators {
                            margin-right: auto;
                        }
                    }
                }
            } 
        }

    }

    .icons-list {
        li {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: $hd-tab) {
                height: 62px;
                width: 20%;
                border-right: 1px solid #dcdcdc;
                flex-direction: column;
            }
    
            svg {
                min-width: 20px;
                min-height: 25px;
                
                @media (min-width: $hd-tab) {
                    margin-right: 8px;
                }
            }

            .option-name {
                // display: none;
                
                @media (max-width: $hd-tab) {
                    display: block;
                    font-size: 8px !important;
                    color: #000;
                    bottom: -5px;
                }
            }
        }
    }

    @media (max-width: $hd-tab) {
        left: 0;
        bottom: 0;
        top: auto;
        width: auto;
        border-radius: 15px 15px 0 0;
        z-index: 1000;
        height: 62px;

        .icons-list {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        &.options-opened {
            width: 100%;
            top: 70px;
            overflow: auto;
            height: auto;

            .cs-page-side-outer {
                width: 100%;
            }

            .icons-list {
                justify-content: flex-start;
            }
        }
    }
}

.article-expandable-body {
    display: none;

    .article-page-side-body-chat {
        height: calc(100vh - 220px) !important;

        @media (max-width: $hd-tab) {
            height: calc(100vh - 253px) !important;
        }
    }

    .article-page-side-chat-left {
        background-color: $primary;
        color: #fff;
    }

    span.mr-5.font-12.font-light {
        margin-right: 0 !important;
    }

    .article-page-side-body,
    .article-page-side-head + .py-3.px-4 {
        height: calc(100vh - 130px);
        overflow: auto;
        @extend .scroll-y;

        @media (max-width: $hd-tab) {
            height: auto;
        }
    }

    .article-page-side {
        animation: none;
    }
}
.article-expandable-options .icons-list li.pill-play,
.show-on-pwa {
    display: none;
}

@media (max-width: $hd-tab) {
    .article-expandable-options .icons-list li.pill-play.pill-play {
        order: 2;
        display: flex;

        svg {
            position: relative;
            margin-top: -29px;
        }
    }

    .pill-playing {
        background-color: $primary;
        
        .option-name {
            color: #fff !important;
        }
    }

    .options-opened {
        .icons-list li {
            border-right: none;
        }
    }

    .pill-peer {
        order: 1;
    }

    .pill-chat {
        order: 3;
    }

    .pill-share {
        order: 4;

        svg {
            path:nth-child(2) {
                fill: $primary;
            }
        }
    }

    .hide-on-pwa {
        display: none;
    }

    .show-on-pwa {
        display: block;
    }

    .article-expandable-body {
        max-height: calc(100vh - 154px);
        overflow: auto;
    }
}

.article-expand .icons-list li{
    @media (min-width: $hd-tab) {
        margin-top:15px;
        display: block;
        justify-content: center;
        text-align: center;
    }
}