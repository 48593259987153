.flex {
  @extend .d-flex;
  @extend .flex-wrap;
}

.align-items-center,
.justify-content-center {
  @extend .flex;
}

.flex-center {
  @extend .flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.m-h-100 {
  min-height: 100%;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

@media (max-width: $desktop) {
  .w-lg-100 {
    width: 100%;
  }
}

@media (max-width: $hd-tab) {
  .w-md-100 {
    width: 100%;
  }
}

@media (min-width: $hd-tab ) {
  .h-md-100 {
    height: 100%;
  }
}

@media (max-width: $hd-tab ) {
  .w-md-100 {
    width: 100%;
  }
}

@media (max-width: $mobile) {
  .w-xs-100 {
    width: 100%;
  }
}

$mb: 10,20,25,42,30;

@each $value in $mb {
  .mb-#{$value} {
    margin-bottom: #{$value}px !important;
  }
}

$mb-sm: 16;

@each $value in $mb-sm {
  @media (max-width: $mobile) {
    .mb-sm-#{$value} {
      margin-bottom: #{$value}px;
    }
  }
}

.p-20 {
  padding: 20px;
}

.lh-1,
.line-height-1 {
  line-height: 1.2 !important;
}

$fills:'primary' $primary, 'white' #fff,'black' #000, 'less-black' #383838, 'grey' #8f908f;

@each $name,$value in $fills {
  .fill-#{$name} {
    fill: #{$value};
  }
  .stroke-#{$name} {
    stroke: #{$value};
  }
}

.fill-primary-dark {
  fill: darken($primary, 10%);
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.bg-light-grey {
  background-color: #D8D8D8 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-42 {
  margin-top: 42px;
}

.create-campaign-margin {
    @media (min-width: $hd-tab) {
    margin-top: 58px;
    z-index: 21 !important;
  }
}

.z-index-20 {
  z-index: 20 !important;
}

.see-all-callout {
  @media (min-width: $hd-tab) {
    z-index: 20 !important;
  }
}

body .mt-sm-40 {
  @media (max-width: $hd-tab) {
    margin-top: 40px !important;
  }
}

body .overflow-lg-visible {
  @media (min-width: $hd-tab) {
    overflow: visible !important;
  }
}

.flex-auto {
  flex: auto !important;
}

.Toastify__toast--error {
  background-color: $primary;
}

.Toastify__toast--success {
  background-color: $primary;
}

.Toastify__toast-body {
  padding-left: 40px;
  background-size: 30px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url('/toast_icon/city_logo.png');
  padding: 20px 25px 18px 40px;
  margin-left: 16px;
}

.Toastify__toast {
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.Toastify__toast-container--top-right {
  right: -5px !important;
}

.Toastify__toast-container {
  @media (min-width: $hd-tab) {
    min-width: 500px;
  }
}

.text-current-color {
  color: currentColor !important;
}

.opacity-half {
  opacity: 0.5;
}

.transform-none {
  transform: none !important;
}

.img-invert {
  filter: invert(1);
}
