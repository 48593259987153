.icons-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #ccc;
    justify-content: space-between;
}

.icon-outer {
    width: 150px;
    height: 90px;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.icon-name {
    font-weight: 600;
    margin-top: 10px;
    word-break: break-all;
}

.icon-outer svg {
    width: 50px;
    height: auto;
}
