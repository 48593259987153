.shar-table {
  th {
    font-weight: normal;
    font-family: $font-bold;
    font-size: 1rem;

    &:last-child {
      min-width: 34px;
    }
  }

  tbody {

    tr {
      &:nth-of-type(odd){
        background: $table-row-bg-color;
        box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.12);
      }
    }

    td {
      padding: 0.5rem 0.75rem;
      border-top-color: $table-td-border-color;
      font-size: 0.875rem;
      vertical-align: middle;
    }
  }

  .td-min-width {
     min-width: 20px;
  }
}

.registrant-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
}

.registants-table {
  thead {
    th {
      padding: 0.5rem 0.75rem;

      @media (max-width: $desktop) {
        white-space: nowrap;
        padding: 0.5rem 1rem;
      }
    }
  }
}

.table-action-icons {
  img {
    max-width: 18px;
    max-height: 22px;
    object-fit: contain;
  }
}

.table-plus-icon {
  font-size: 1.75rem;
  line-height: 1;
}

.assignment-table-draggable {
  tr{
    th:first-child,td:first-child{
      width: 65%;
    }
    th:nth-child(2),td:nth-child(2){
      width: 20%;
      min-width: 250px;
    }
  }
}

.section-table-draggable {
  tr{
    th:first-child,td:first-child{
      width: 85%;
    }
    th:nth-child(2),td:nth-child(2){
      width: 15%;
      min-width: 200px;
    }
  }
}