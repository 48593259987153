.event-home,
.group-home,
.group-detail {
    padding: 40px 36px;

    @media (max-width: $hd-tab) {
        padding: 20px;

        .shar-form .form-group input.form-control {
            &::placeholder {
                font-family: $font-regular-italic !important;
            }
        }
    }
}

.event-home {
    @media (max-width: $hd-tab) {
        padding: 24px 20px;
    }
}

.event-head {
    margin-bottom: 50px;
    @media (max-width: $hd-tab) {
        margin-bottom: 20px;
    }
}


.additional-card {
    box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
    border-radius: 15px;
    width: 320px;

    @media (max-width: $hd-tab) {
        width: 100%;
    }

    &-date {
        background-color: $primary;
        color: $white;
        box-shadow: 0 4px 8px 0 rgba(84,104,255,0.30);
        border-radius: 6px;
        top: auto;
        left: 10px;
        width: 67px;
        height: 67px;
        bottom: -34px;
    }
}

.additonal-card-main-img {
    width: 100%;
    height: 113px;
    object-fit: cover;
}

.event-tag {
    margin-left: 76px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.event-footer-img {
    width: 16px;
    height: 16px;
    border: 1px solid #DFDFDF;
    border-radius: 50%;
}

.event-container {
    .additional-card {
        &:not(:last-child) {
            @media (min-width: $hd-tab) {
                margin-right: 40px;
            }
        }
    }
}

.create-event-upload {
    .post-pic {
        height: 255px;
    }

    .add-icon-upper {
        width: 25px;
        left: calc(50% - 12px);
    }
}

.btn-rounded-2 {
    border-radius: 8px;
}

.btn-rounded-3 {
    border-radius: 15px;
}

.create-group-upload {
    &.additional-card  {
        width: 315px;

        @media (max-width: $hd-tab) {
            width: 100%;
        }
    }

    .post-pic {
        height: 165px;
    }

    .add-icon-upper {
        width: 25px;
        left: calc(50% - 12px);
    }

    &-pwa {
        .post-pic {
            height: 188px;
        }
        .add-icon-upper {
            width: 55px;
            left: 50%;
            top: 50px;
            transform: translateX(-50%);

            circle {
                fill: $light-grey-bg;
            }

            line {
                stroke: $primary;
            }
        }

        textarea.form-control {
            height: 140px !important;
            resize: none;
        }

        .upload-text {
            position: absolute;
            bottom: 50px;
            transform: translateX(-50%);
            margin-left: 10px;
        }
    }
}

.group-container {
    max-width: 1007px;

    .additional-card,
    .my-group {
        &:not(:last-child) {
            @media (min-width: $hd-tab) {
                margin-right: 14px;
            }
        }
    }

    &.overflow-auto {
        padding-left: 10px;
        transform: translateX(-10px);

        @media (max-width: $hd-tab) {
            padding-right: 10px;
            width: calc(100% + 10px);
        }
    }
}

.invitation-card {
    &.additional-card {
        width: 204px;
    }

    .additional-card-head {
        img {
            width: 100%;
            height: 103px;
            object-fit: cover;
        }
    }

    &-body {
        height: calc(100% - 103px);
    }
}

.toggle-switch-pwa {
    padding-left: 14px;
    margin-top: 20px;

    .toggle-switch {
        label .toggle-track {
            background: #eee;
            border-color: #ccc;
            height: 16px;
            width: 30px;

            &:before {
                background: #929191;
                height: 10px;
                width: 10px;
                top: 2px;
                right: 3px;
            }
        }

        input[type="checkbox"]:checked {
            + label .toggle-track {
                background-color: $primary;
                border-color: $primary;
                &:before {
                    background-color: #fff;
                    right: 15px;
                }
            }
        }
    }
}

.group-testimonial-creatable-card {
    height: 112px;
    background-color: $light-grey-bg;

    .add-icon-upper {
      width: 25px;
      left: calc(50% - 12px);
    }
}

.create-card-pwa {
    box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
    border-radius: 15px;
    height: 112px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
    &-btn {
        min-height: 58px;
        letter-spacing: 1px;
    }

    &-outer {
        .page-sidebar-body {
            display: flex;
            flex-direction: column;
            background: white;
            min-height: calc(100vh - 100px);
            padding: 16px 22px;

            @media (max-width: $hd-tab) {
                margin-top: -2px;
            }

            @media (min-width: $hd-tab) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        .page-sidebar-head {
            .display-8  {
                font-size: 18px !important;
                margin-left: 0 !important;
            }
        }
    }

    &:hover,
    &:focus,
    &.active {
        border: 6px solid $primary;
        background-color: $light-grey-bg;
    }

    @media (min-width: $hd-tab) {
        border: 6px solid transparent;
        cursor: pointer;
        width: 380px;
        margin: 0 auto 30px;

        .card-body {
            text-align: left !important;
            display: flex;
            align-items: center;

            img.mr-3 {
                margin-right: 24px !important;
            }
        }

        &:hover {
            .empty-badge {
                &-fill {
                    fill: #fff;
                }
            }
        }
    }

    @media (max-width: $hd-tab) {
        height: auto;

        .card-body {
            display: flex;
            align-items: center;
        }
    }
}

.testimonies-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    min-width: 60px;

    &.active {
        box-shadow: 0 0 0 5px #F18F2A;
    }
}

.testimonies-outer {
    overflow: auto;
    margin: -20px -19px 40px 0;
    padding: 6px;
}

.testimonies-info {
    margin-right: 16px;
}

.updated-time {
    max-width: 160px;
}

.agenda-time {
    min-width: 80px;
}

.event-details-outer {
    &:not(:empty) {
        + .article-updated-time {
            justify-content: flex-end;

            @media (max-width: $hd-tab) {
                display: none !important;
            }
        }

        @media (max-width: $hd-tab) {
            ~ .article-page-members {
                order: 0;
                background: $primary;
                color: #fff !important;
                border-bottom: none !important;
                padding: 10px 15px !important;
                width: fit-content;
                border-radius: 8px;
                margin: auto;
                margin-bottom: 40px;
                box-shadow: $pwa-btn-box-shadow !important;
                letter-spacing: $btn-lg-letter-spacing;
                text-transform: uppercase;
                margin-top: 20px;
                margin-bottom: 0;
                margin-left: 0;

                svg {
                    margin-right: 20px;
                    width: 20px;
                    margin-top: -3px;
                }

                .fill-primary {
                    fill: #fff;
                }
            }

            ~ .article-description {
                .display-7 {
                    display: block;
                }


                .article-page-text {
                    &::first-letter {
                        font-family: $font-family-base;
                        font-size: 14px;
                        margin-right: 0;
                    }
                }
            }
        }


        ~ .article-page-inner-body {
            .carousel-indicators {
                display: none;
            }
        }
    }
}

.shar-calendar {
    .MuiPickersBasePicker-pickerView {
        box-shadow: 0 2px 8px 0 rgba(39,34,98,0.14);
        border-radius: 3px;
        margin: 5px;
        max-width: 100%;
        min-height: auto;

        * {
            font-family: $font-bold;
        }
    }

    .MuiPickersCalendarHeader-switchHeader {
        justify-content: center;
        margin-top: 10px;

        .MuiPickersCalendarHeader-transitionContainer {
            max-width: 150px;
        }

        button {
            .MuiIconButton-label {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center;
            }

            &:first-child {
                .MuiIconButton-label {
                    background-image: url(../assets/images/icons/leftArrow.svg);
                }
            }

            &:last-child {
                .MuiIconButton-label {
                    background-image: url(../assets/images/icons/rightArrow.svg);
                }
            }

            svg {
                display: none;
            }
        }
    }

    .MuiPickersCalendarHeader-daysHeader,
    .MuiPickersCalendar-week {
        justify-content: space-between;
        margin: 0 24px;

        > span,
        > div {
            margin: 5px;
            min-width: 36px;

            button.active {
                &:after {
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $primary;
                    position: absolute;
                    bottom: -5px;
                }
            }
        }
    }

    .MuiPickersCalendar-transitionContainer {
        min-height: 276px;
    }

    .MuiTypography-caption {
        font-size: 14px;
        color: #3c3c3c;
    }

    .MuiTypography-body2 {
        font-size: 16px;
    }

    .MuiPickersCalendarHeader-daysHeader {
        margin: 20px 24px;
    }

    .MuiPickersCalendarHeader-transitionContainer {
        height: 26px;
    }

    .MuiTypography-body1 {
        font-size: 18px;
    }

    .MuiPickersDay-daySelected {
        background-color: $primary;
    }

    @media (max-width: $mobile) {
        .MuiPickersStaticWrapper-staticWrapperRoot {
            min-width: auto;
        }

        .MuiPickersCalendarHeader-daysHeader {
            margin: 20px 0px;
        }

        .MuiPickersCalendarHeader-daysHeader > span,
        .MuiPickersCalendarHeader-daysHeader > div,
        .MuiPickersCalendar-week > span,
        .MuiPickersCalendar-week > div {
            margin: 5px 0px;
            // min-width: 44px;
        }

        .MuiPickersCalendar-week {
            margin: 0;
        }
    }
}

.sidebar-position-top {
    top: 56px;
}

.group-home {
    .home-page-heading {
        max-width: 1110px;
    }
}

.community-section.mt-42.slider-mob {
    margin-bottom: 42px;
}

.group-detail {
    .article-section:not(:last-child)  {
        @media (max-width: $hd-tab) {
            transform: translateX(-10px);

            .article-section-outer {
                margin-right: -29px;
            }
        }
    }
}

.empty-badge-fill {
    fill: rgba($primary,0.15);
}
