.edit-profile {

  color: $primary-color;

  .edit-padding-left {
    @include padding(12px,0px,0px,85px);
  }

  &-margin {
    box-shadow: -2px 3px 6px 0px rgba(150, 150, 150, 0.6);
  }

  .close-btn-position {
    position: absolute;
    right: 37px;
    top: -16px;
    font-size: 45px;
    cursor: pointer;
    color: $primary-color;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .change-photo-position {
    position: absolute;
    top: auto;
    right: -3px;
    bottom: 2px;
  }

  .font-16-light-italic {
    font-size: 1rem;
    font-family: $font-light-italic;
  }

  .photo-icon-position {
    position: absolute;
    left: 5px;
  }

  .btn-light {
    @include padding(.3rem, .3rem,.3rem,.3rem);
  }

  .bg-camera {
    background-color: $camera-icon;
  }

  .image-position{
    display: block;
  }

}

.edit-profile-img {
  img {
    width: 82px;
    height: 82px;
    object-fit: cover;
  }
}

@media (max-width: $hd-desktop) {
  .edit-profile-img {
    img {
      width: 82px;
      height: 82px;
      object-fit: cover;
    }
  }
}

@media (max-width: $hd-tab) {
  .edit-profile .edit-padding-left {
    padding-left: 30px;
  }
}

.edit-form {
  input,
  select,
  textarea{
    max-width: 100%;
  }

  .btn-primary {
    padding: 6px 12px !important;
  }
}

.pwa-profile-ac-item.bg-light {
  background-color: #EBEAF0 !important;
}

.badges-container {
  background: #FFFFFF;
  box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
  border-radius: 15px;
  padding: 12px 20px;

  @media (max-width: $hd-tab) {
    flex-wrap: nowrap !important;
    overflow: auto;
    max-width: 100%;
  }
}

.passport-badge {
  img {
    width: 75px;
    min-width: 75px;
    height: auto !important;
  }
}

.badge-earned {
  margin-right: 36px;

  &:last-child {
    margin-right: 10px;
  }

  img {
    height: 45px;
  }
}

.badge-display {
  width: 150px;
  height: 150px;
  background-image: linear-gradient(180deg, #E7E8FF 0%, #CACDFF 100%);

  img {
    width: 80px;
  }
}

.success-card-outer {
  .article-outer,
  .my-group {
    margin-left: auto;
    margin-right: auto;
  }

  .landing-page-stories .article-outer {
    width: auto !important;
  }
}

.earned-badge {
  min-width: 88px;

  &:last-child {
    margin-right: 0 !important;
  }
}

.heading-badge-img {
  width: 25px;
  margin-right: 15px;
}

.chevron-right {
  transform: rotate(180deg);
}

@media (max-width: $hd-tab) {
  p.font-medium.display-8.ml-3.pt-4.pb-5.mb-3 {
    ~ .btn {
      margin-bottom: 60px;
    }
  }

  .edit-img-resizer {
    .resizer {
      input[type="range"] {
        margin: auto;
        -webkit-appearance: none;
        position: relative;
        overflow: hidden;
        height: 40px;
        cursor: pointer;
        border-radius: 4px;

        &::-webkit-slider-runnable-track {
          background: #999;
          border-radius: 4px;
          height: 6px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 20px; 
            height: 20px;
            background: $primary;
            border: 2px solid  $primary;
            border-radius: 50%;
            margin-top: -6px;
        }

        &::-moz-range-thumb {
            width: 20px; 
            height: 20px;
            background: $primary;
            border: 2px solid  $primary;
            border-radius: 50%;
            margin-top: -6px;
        }
      }
    }
  }
}

.social-contact-list {
  @media (max-width: $hd-tab) {
    .d-flex {
      min-width: 58px;
      text-align: center;
      margin-bottom: 24px;
      
      &:not(:last-child) {
        margin-right: 6px !important;
      }
    }
  }
}
