//colors are curretly set according to Ahura CRM
.night-mode .main-container  {
  background-color: #1d1d1d;
  color: #fff;

  .courses-tab .create-assignments-modal .msq-activator input {
    background: linear-gradient(180deg, #1D1D1D 0%, #1D1D1D 100%);
   }

  .form-control,
  .follower-tab {
    background-color: transparent;
    color: #fff;
  }

  .follower-info-outer + .mr-20 img,
  .article-page-back svg,
  .page-sidebar-head svg,
  .settings-icon,
  .create-card-pwa .chevron-right,
  .article-dropdown .btn-none svg,
  .pwa-upload-field .attacment-icon,
  .direct-message .close-icon-width,
  .direct-message-container img.width-10,
  .btn-link img.width-20,
  .client-profile-more-icon,
  img.more-actions-options-icon,
  .add-category .ml-auto svg,
  img.menu-add-icon,
  .shops-tab .align-items-center.w-100.mb-4 svg,
  .course-sidecallout .text-center.cursor-pointer svg,
  svg.assignment-icon,
  svg.mr-3.ml-n4.cursor-pointer,
  .section-table-draggable thead .cursor-pointer svg,
  .section-table-draggable + .cursor-pointer svg,
  .align-items-center.font-weight-bold.font-18 svg.mr-3,
  .align-items-center.font-weight-bold.font-18.my-4 + .cursor-pointer svg,
  .msq-activator svg.ml-2,
  .communication-nav .network-nav .vertical-menu,
  img.category-display,
  .product-action img.cursor-pointer,
  img.approve-action-icon,
  .shop-color-picker-box svg,
  .see-all-callout .home-page-padding svg,
  .toggle-icon img,
  .article-page-side-head svg,
  .agenda-side-body .ml-auto.cursor-pointer svg,
  .event-close-btn svg,
  .licenses-icon-city,
  .licenses-icon-call,
  .licenses-icon-mail,
  .licenses-icon-web,
  .licenses-icon-card {
    filter: invert(1)
  }

  .img-invert {
    filter: none;
  }

  .client-profile-inner .nav-tabs .nav-link,
  .shar-form.search-form input::placeholder,
  .shar-form.search-form select.round.form-control,
  .client-profile-changed .ahura-client-profile-inner .nav-tabs .nav-link:not(.active):not(:hover),
  .shar-form.search-form .article-dropdown .btn-none,
  .pwa-upload-field span,
  .table,
  .text-networks,
  .edit-profile,
  .shar-form input::placeholder,
  .btn-link,
  .communication-nav .networks li:hover a:hover,
  .dropdown-item,
  .campaings-layout,
  .campaings-layout .form-control::placeholder,
  .public-DraftEditorPlaceholder-inner,
  .campaings-layout .placeholder-font,
  .profile-text-dark,
  .ElementsApp .InputElement::placeholder,
  .credit-card-white .card-payment-info,
  .courses-tab .nav-tabs .nav-link,
  .btn-post-size,
  .table-hover tbody tr:hover,
  .shops-tab .nav-tabs .nav-link,
  .shop-tag-input,
  .messages ul li.replies p,
  option,
  .article-dropdown .btn-none .existing-article-outer,
  .page-sidebar-outer,
  .community-field-text,
  .cs-lesson-indicators .font-14,
  .existing-article-body .existing-article-heading span,
  .article-expandable-options .icons-list li .option-name {
    color: #fff;
  }

  .existing-article-body .existing-article-heading + .text-muted {
    color: #d8d8d8 !important;
  }

  
  .shar-form.search-form .form-type-selected span.font-bold.display-8.text-dark,
  .landing-page-stories .article-new.article-outer .pt-3.pb-2.px-3 .article-category,
  .landing-page-stories .article-new.article-outer .pt-3.pb-2.px-3 .article-title,
  .shar-form.search-form textarea.form-control::placeholder,
  .shar-form.search-form .event-date-picker input,
  .shar-table span.font-weight-bold.text-dark.ml-2.cursor-pointer,
  .course-sidecallout,
  .section-table-draggable tbody tr .text-dark,
  .assignment-table-draggable tbody tr .text-dark,
  .table .text-dark,
  .article-outer .text-dark,
  .key-detail .text-dark,
  .notification-item-inner a.text-dark,
  .pill-option.active,
  .add-new-section input::placeholder,
  .agenda-details-inner,
  .article-page-outer .article-updated-time .text-secondary {
    color: #fff !important;
  }
  
  .pwa-form.article-page-side-input input[type="text"],
  .shar-form.search-form-pwa.sidebar-form.search-form-full input[type="text"],
  .search-follower,
  .chat-details .input-msg-box .input-group {
    background: linear-gradient(180deg, #3E3E3E 0%, #1D1D1D 259.19%);
    border: 1px solid #D2D2D2;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  .page-sidebar-inner,
  option,
  .page-sidebar-inner .register-outer,
  .bg-light-secondary,
  .page-sidebar-body,
  .direct-message .grey-close-chip,
  .shar-table tbody tr:nth-of-type(odd),
  .credit-card-white .card-payment-info,
  .course-sidecallout,
  .courses-tab .mcq-set,
  .see-all-callout,
  .article-outer,
  .shar-form.search-form-pwa.sidebar-form input[type="tel"],
  .community-section-inner .community-outer,
  .pwa-callout-decorator-sec {
    background-color: #3E3E3E;
  }

  .create-card-pwa,
  .rdw-editor-toolbar,
  .dropdown-menu,
  .communication-nav,
  .assignment-list-inner,
  .sub-product-callout > .page-sidebar-inner,
  .sub-product-callout > .page-sidebar-inner > .page-sidebar-body,
  .btn-support-team,
  .statement .box,
  .article-page-side-outer,
  .agenda-details-inner  {
    background-color: #1d1d1d;
  }

  .ModalOpen.bg-white,
  .add-new-section,
  tr.read {
    background-color: #1d1d1d !important;
  }

  #header.header {
    background-color: #1d1d1d !important;
    border-color: transparent;

    .notifications-outer {
      .dropdown-toggle {
        img {
          filter: invert(1);
        }
      }
    }

    .notification-indicator {
      background: linear-gradient(131.62deg, #00D3CA -6.12%, #00A89A 82.96%);
    }

    .notification-overflow {
      background: linear-gradient( 180deg, #00B7AA 0.12%, #1D1D1D 100%) !important;

      .unread {
        background-color: rgba(0, 0, 0, 0.45) !important;

        a {
          color: #fff !important;
        }
      }
    }

    .client-profile-toggle {
      background: linear-gradient( 180deg, #00B7AA 0.12%, #1D1D1D 100%) !important;
      
      .btn-primary.dropdown-toggle {
        color: #fff !important;
        
        &[aria-expanded="false"] {
          background: linear-gradient(180deg, #00B7AA 0.12%, #1D1D1D 100%) !important;
          
          .toggle-icon {
            img {
              filter: invert(1);
            }
          }
        }
      }

      .dropdown-menu.show {
        background-color: transparent;
        color: #fff;
      }

      .dropdown-item {
        color: #fff;

        &:hover {
          background-color: #163f3c;
        }

        svg {
          #Home-Community---Profile-Switcher {
            fill: #fff;
          }
        }
      }

      @media (max-width: $hd-tab) {
        background: transparent !important;
        
        .btn-primary.dropdown-toggle[aria-expanded="false"] {
          background: transparent !important;
        }

        .toggle-icon {
          filter: invert(1) brightness(2);
        }

        .dropdown-menu.show {
          background-color: #1d1d1d;
        }
      }
    }

    .client-profile-toggle-item {
        &:hover,
        &.active {
           background-color: rgba(0, 0, 0, 0.5);
        }
    }
    @media (max-width: $hd-tab) {
      ~ .px-0.h-100.container-fluid .navbar-dark .navbar-toggler-icon {
        filter: none;
      } 
    }
  }

  .sidebar {
    background: #1d1d1d;

    .fill-black {
      fill: #fff;
    }

    @media (max-width: $hd-tab) {
      .side-navbar {
        background: #1d1d1d !important;
        .text-dark {
          color: #fff !important;
        }
      }
    }
  }

  .page-sidebar-outer,
  .campaings-layout .page-sidebar-body,
  .campaings-layout .page-sidebar-inner,
  .communication-nav .network-nav-suboptions ul.bg-white:not(:empty),
  .nav-tabs .nav-link.active,
  .section-table-draggable tbody,
  .section-table-draggable tbody tr,
  .assignment-table-draggable tbody,
  .assignment-table-draggable tbody tr,
  .additional-field-details,
  .additional-field-details:after {
    background-color:  transparent !important;
  }

  .shar-table tbody tr.unread,
  .dropdown-item:hover,
  .communication-nav .network-nav .row.bg-white,
  .sub-option-menu,
  .article-expandable-options,
  .article-page-side-outer {
    background: #3E3E3E !important;
  }

  .course-sidecallout:not(.course-sidecallout-active) {
    box-shadow: 1px 2px 4px #4b4b4b;
  }

  .mcq-set-main .rounded-circle {
    background: linear-gradient(180deg, #323232 76.11%, #FB7F3D 198.47%);
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .assignment-callout {
    .page-sidebar-head {
      background: linear-gradient(180deg, #3E3E3E 0%, #1D1D1D 100%);
      border: 1px solid #1D1D1D;
    }

    .page-sidebar-inner,
    .page-sidebar-body {
      background-color: #1D1D1D !important;
      > .d-flex.align-items-center.pb-2 {
        background: linear-gradient(180deg, #3E3E3E 0%, #02988B 643.12%);
        box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22);
        border-radius: 10px;

        .course-sidecallout {
          background: transparent;
          box-shadow: none;
          color: #fff !important;
        }
      }

      .mcq-set {
        background-color: #3E3E3E;
      }
    }
  }

  .nav-tabs .nav-link.active,
  .lesson-tile-primary .b-600.font-14.mt-1,
  .mcq-set-dark .b-600.font-14.mt-1 {
    color: $primary;
  }

  .post-pic,
  .shop-tag-label,
  .shop-color-picker-box,
  .notification-item-inner:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .messages ul li.replies p {
    background: #8e8e8e;
  }

  .notification-overflow .text-primary {
    color: lighten($primary, 20%) !important;
  }

  .citizen-box,
  .upgrade-plan-outer .box {
    background: linear-gradient(180deg, $primary 0.12%, #1D1D1D 100%);

    .text-primary {
      color: #fff !important;
    }
  }

  .card-detail,
  .card-details {
    #card-number,
    #card-expiry,
    #card-cvc,
    input.form-control {
      background-color: #f2f2f2;
      color: #495057;

      &::placeholder {
        color: #344356;
      }
    }
  }
  .shar-form.search-form-pwa select.round {
    background-image: url(../assets/images/icons/chevron_down_white.svg);
  }
} 