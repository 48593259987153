button {

  &.btn-light:focus {
    border: 1px solid transparent;
    height: 100%;
    box-shadow: none;
  }
}

.btn-outline-primary {
  @include button-size(0.7rem, 1.875rem, 1.12rem, 1rem, 0.2rem);
  font-family: $font-medium;

  &:hover,
  &:active {
    border: 1px solid $primary !important;
    color: $white !important;
  }

  &:active {
    box-shadow: none;
  }
}

.btn-primary:disabled {
  background-color: #D8D8D8;
  border-color: #D8D8D8;
  color: $white;
}

.btn-outline-dark {
  @include button-size(0.7rem, 1.875rem, 1.12rem, 1rem, 0.3rem);
  color: $black;
  min-width: 7.5rem;
  font-family: $font-bold;
  display: inline-block;
  border: 0.125rem solid $black;
  box-shadow: none;
  cursor: pointer;
  @include transition(all, 0.2s, ease-in-out);
  @include hover-focus-active {
    background: $primary;
    font-family: $font-bold;
    border: 0.125rem solid $primary !important;
    outline: none;
    color: $white;
    box-shadow: none;
  }
}

.btn-primary {
  border-radius: $btn-border-radius;
  border: 1px solid $primary;
  color: $white;
  @include hover-focus-active {
    border: 1px solid $primary;
  }

  @media (max-width: $hd-tab) {
    border-radius: $pwa-btn-border-radius;
  }
}

@media(max-width:64rem) {
  .btn-primary,
  .btn-outline-dark {
    @include padding(0.58rem, 0.4rem, 0.58rem, 0.4rem);
  }
}

.btn-light {
  @include border-radius(0.25rem);
  font-size: 18px;
  font-family: $font-family-base;
  color: $light-black;
}

.btn-secondary {
  font-family: $font-bold;
  font-size: 20px;
  @include padding (4px, 6px, 4px, 6px);
  min-width: 160px;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:focus {
  outline: 0;
  height: 100%;
  box-shadow: none;
}

.btn {
  min-width: 130px;
  min-height: 40px;
  font-size: 1rem;
  font-family: $font-bold;
  text-transform: $btn-text-transform;
  overflow: hidden;
  border-radius: $btn-border-radius;
  
  @media (max-width: $hd-tab) {
    border-radius: $pwa-btn-border-radius;
    text-transform: $pwa-btn-text-transform;
  }

  &-disabled,
  &[disabled] {
    color: $text-grey;
    border-color: currentColor;
    pointer-events: none;
    background: $white;
  }

  &-primary {
    color: $white;
    @include hoverEffectFilled($white,$primary,top);
    box-shadow: $btn-box-shadow !important;

    @media (max-width: $hd-tab) {
      box-shadow: $pwa-btn-box-shadow !important;
    }

    &-outlined {
      border: 1px solid $primary !important;
      box-shadow: none !important;
      color: $primary;
      position: relative;
      z-index: 1;
      @include hoverEffectOutlined($primary,$white,top);
    }
  }

  &-white {
    border: 2px solid $white;
    color: $white;
    position: relative;
    z-index: 1;
    @include hoverEffectOutlined($white,$primary,top);
  }

  &-text {
    background: white;
    color: $text-grey;
    border-radius: 0;
  }

  &-alternate {
    color: $white;
    z-index: 1;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);;
      left: -1px;
      top: -1px;
      background: $alternate-color;
      transition: transform 0.2s ease-in-out;
      transform-origin: bottom;
      z-index: -1;
      // border-radius: $btn-border-radius;
    }

    @media (min-width: $hd-tab) {
      &:hover{
        color: $alternate-color !important;
        border: 1px solid $alternate-color;
        &:after{
          transform: scaleY(0);
        }
      }
    }

    &:focus{
      color: $alternate-color !important;
      border: 1px solid $alternate-color;
      &:after{
        transform: scaleY(0);
      }
    }

    @media (max-width: $hd-tab) {
      &:hover{
        color: $white;
      }
    }

    &-outlined{
      color: $alternate-color;
      border: 2px solid $alternate-color;
      position: relative;
      z-index: 1;
      @include hoverEffectOutlined($alternate-color,$white,bottom);
    }
  }

  &.btn-lg {
    min-width: 220px;
    height: 45px;
    font-size: 1.125rem;
  }

  &.btn-md {
    font-size: 1.125rem;
    width: 150px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &-link {
    padding: 0;
    color: inherit;
    width: auto;
    min-width: auto;
    height: auto;
    min-height: auto;
    color: $light-black;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  &-toolbox {
    display: flex;
    flex-direction: column;
    min-width: 49px;
    height: 49px;
    font-size: 12px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &:hover {
      color: $white !important;

      &:after {
        display: none;
      }
    }
  }

  &-close {
    font-size: 2.5rem;
    line-height: 1;

    @media (max-width: $mobile) {
      font-size: 2rem;
    }
    &-sm {
        font-size: 1.25rem;
    }
  }

  &-sm {
    min-width: 100px !important;
    min-height: 30px !important;
    font-size: 0.875rem !important;
  }
}

.close-button {
  position: relative;
  z-index: 1;
}

.lg-btn {
  font-size: $btn-lg-size;
  min-height: $btn-lg-height;
  letter-spacing: $btn-lg-letter-spacing;

  &.btn-link.btn-cancel{
    color: #383838;
  }
}

.btn-rounded {
  border-radius: 25px !important;
}

input.btn-primary {
  &:focus {
    color: #fff !important;
  }
}
