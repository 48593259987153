.sidebar {
  box-shadow: 2px 2px 7px 0 rgba(0,0,0,0.24);
  position: fixed;
  width: 55px;
  height: calc(100% - 58px);
  z-index: 10;
  top: 58px;
  overflow: auto;
  background: $white;

  .side-navbar {
    li {
      a {
        @extend .p-1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        @include transition(all ease-in-out 0.24s);

        @media (max-width: $fhd-tab){
          justify-content: flex-start;
        }

        @media (max-width: $hd-tab){
          justify-content: flex-start;
        }

        @media (min-width: $fhd-tab) {
          &:hover {
            background: $primary;
  
            .fill-black {
              fill: #fff;
            }
          }
        }

        @media (min-width: $hd-tab) {
          &:hover {
            background: $primary;
  
            .fill-black {
              fill: #fff;
            }
          }
        }
      }

      &.active {
        @media (min-width: $fhd-tab) {
          a{
            background: $primary;
            .fill-black {
              fill: #fff;
            }
          }
        }

        @media (min-width: $hd-tab) {
          a{
            background: $primary;
            .fill-black {
              fill: #fff;
            }
          }
        }
      }

      &:first-child {
        img{
          width: 24px;

          @media (max-width: $fhd-tab) {
            margin-right: 0.5rem;
          }

          @media (max-width: $hd-tab) {
            margin-right: 0.5rem;
          }
        }
      }
    }

    @media (max-width: $fhd-tab) {
      padding: 0 1rem;
    }

    @media (max-width: $hd-tab) {
      padding: 0 1rem;
    }

  }
}

.communication-icon {
  width: 24px
}

.dashboard-icon {
  width: 32px;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('.././assets/images/collapse-icon.svg');
}

.sidebar .dashboard-p-20 {
  @include padding(1.25rem,1.25rem,1.25rem,1.25rem);
}

.side-menu-shadow {
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
}

@media screen and (max-width: $fhd-tab) {
  .nav-share {
    z-index: 101;
    position: fixed;
    top: 9px;
  }
}

@media screen and (max-width: $hd-tab) {
  .nav-share {
    z-index: 101;
    position: fixed;
    top: 9px;
  }
}

//For mobile responsiveness

@media (max-width: $fhd-tab) {

  .sidebar{
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
  }

  .side-navbar {
    transition: all 0.24s cubic-bezier(0.29, 0.53, 0.08, 0.54);
    transform: translateX(-100%);
    background: white;

    .dashboard-card-img {
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }

    .dashboard-card-right {
      font-size: 22px;
      margin: 0 !important;
    }

  }

  .navbar-collapse.show {
    .side-navbar {
      transform: translateX(0);
    }
  }

  #menu-list.ModalOpen,
  #menu-summary.ModalOpen,
  .top-on-sm {
    position: absolute;
    z-index: 10;
    background: $white;
    animation-name: slideLeft !important;
  }

  .navbar-toggler:not(.collapsed){
    .navbar-toggler-icon {
      background-image: url('../assets/images/icons/close_icon_white.svg');
    }
  }

}

@keyframes slideRight{

  0% {
      transform: translateX(100%);
      opacity: 0.3;
  }

  100% {
      transform: translateX(0);
      opacity: 1;
  }

}

@media (max-width: $hd-tab) {

  .sidebar{
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
  }

  .side-navbar {
    transition: all 0.24s cubic-bezier(0.29, 0.53, 0.08, 0.54);
    transform: translateX(-100%);
    background: white;

    .dashboard-card-img {
      margin-right: 10px;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }

    .dashboard-card-right {
      font-size: 22px;
      margin: 0 !important;
    }

  }

  .navbar-collapse.show {
    .side-navbar {
      transform: translateX(0);
    }
  }

  #menu-list.ModalOpen,
  #menu-summary.ModalOpen,
  .top-on-sm {
    position: absolute;
    z-index: 10;
    background: $white;
    animation-name: slideLeft !important;
  }

  .navbar-toggler:not(.collapsed){
    .navbar-toggler-icon {
      background-image: url('../assets/images/icons/close_icon_white.svg');
    }
  }

}

@keyframes slideRight{

  0% {
      transform: translateX(100%);
      opacity: 0.3;
  }

  100% {
      transform: translateX(0);
      opacity: 1;
  }

}

.menu-add-icon {
    width: 16px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.create-neighborhood-mobile-input {
  input[type="tel"] {
      max-width: 315px;
      padding-left: calc(#{$input-padding-x} + 90px) !important;

      @media (max-width: $fhd-tab) {
        max-width: 100%;
      }

      @media (max-width: $hd-tab) {
          max-width: 100%;
      }
  }

  .PhoneInput {
      position: relative;

      .PhoneInputCountry {
          z-index: 1;
          left: 5px;
          max-height: calc(#{$pwa-input-height} - #{$pwa-input-padding-y});
      }
  }

  .text-right.form-field-width.pr-3 {
    max-width: 335px !important;
  }
}

.tiles-container {
  $tile-outer: $pwa-input-height +  333px;
  max-height: calc(100vh - #{$tile-outer});
  @extend .scroll-y;

  @media screen {
    $tile-outer: $pwa-input-height +  253px;
    max-height: calc(100vh - #{$tile-outer});
  }

  .group-section-outer {
    &:not(:first-child) {
      margin-top: -1px;
    }

    &:last-child {
      .border-bottom {
        border-bottom: none !important;
      }
    }
  }
}

.tiles-container-pwa-footer {
  $tile-outer: $pwa-input-height +  127px;
  max-height: calc(100vh - #{$tile-outer}) !important;
  @extend .scroll-y;

  @media screen {
    $tile-outer: $pwa-input-height +  253px;
    max-height: calc(100vh - #{$tile-outer});
  }

  .group-section-outer {
    &:not(:first-child) {
      margin-top: -1px;
    }

    &:last-child {
      .border-bottom {
        border-bottom: none !important;
      }
    }
  }
}

.form-group {
  .tiles-container {
    padding-top: 10px;
    padding-bottom: 30px;

    &:empty {
      display: none;
    }

    @media (min-width: $fhd-tab) {
      margin-left: -35px;
      margin-right: -35px;
    }

    @media (min-width: $hd-tab) {
      margin-left: -35px;
      margin-right: -35px;
    }
  }
}

.header:not(.header-unpin) {
  @media (max-width: $fhd-tab) {
    + .container-fluid {
      .position-sidebar-fixed,
      .page-sidebar-outer {
        top: 0;
        bottom: 0;
        z-index: 1000;
      }
    }
  }

  @media (max-width: $hd-tab) {
    + .container-fluid {
      .position-sidebar-fixed,
      .page-sidebar-outer {
        top: 0;
        bottom: 0;
        z-index: 1000;
      }
    }
  }
}

img.sidebar-header-icon {
  margin-top: 2px;
}

.page-sidebar-outer {
  color: #000;
  
  @media (max-width: $fhd-tab) {
    position: fixed !important;
  }

  @media (max-width: $hd-tab) {
    position: fixed !important;
  }
}

.article-page-side-outer.full-sidebar {
  .h-md-100.scroll-y.overflow-auto {
    height: auto;
    padding-top: 0;

    > .cursor-pointer.d-flex.align-items-center.event-head {
      display: none !important;
    }

    .mt-3.pb-4 {
      margin-top: 0 !important;
      padding-bottom: 38px !important;

      .font-24-bold.mb-4 {
        font-size: 24px !important;
      }
    }
  }
  > .article-page-side.h-100 {
    > .mt-3.pb-4 {
        margin-top: 0 !important;
        padding: 0 36px 40px 36px !important;

        @media (max-width: $fhd-tab) {
          padding: 0 24px 24px !important;
        }

        @media (max-width: $hd-tab) {
          padding: 0 24px 24px !important;
        }

        .home-page-padding {
          padding: 38px 0 0 10px;

          .group-container.overflow-auto {
            max-width: 100%;
          }
        }
    }

    > .article-section {
      max-width: 100%;
      padding-left: 36px;
      padding-right: 36px;

      @media (max-width: $fhd-tab) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @media (max-width: $hd-tab) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

@media (min-width: $fhd-tab) {
  .article-page-side-outer.full-sidebar {
    position: fixed;
    right: 0;
    max-width: calc(100% - 50px);
    top: 58px;

    .shar-form.search-form-pwa.sidebar-form input[type="text"] {
      margin-left: 0;
      width: 540px;
    }

    .community-section {
      max-width: 100%;
    }
  }
}

@media (min-width: $hd-tab) {
  .article-page-side-outer.full-sidebar {
    position: fixed;
    right: 0;
    max-width: calc(100% - 50px);
    top: 58px;

    .shar-form.search-form-pwa.sidebar-form input[type="text"] {
      margin-left: 0;
      width: 540px;
    }

    .community-section {
      max-width: 100%;
    }
  }
}

@media (max-width: $fhd-tab) {
  .article-page-side-outer.full-sidebar {
    .article-page-side.h-100 {
      > .home-page-padding {
        padding: 0 24px 24px;
      }
      > .mt-3.pb-4 {
        > .h-md-100.scroll-y.dashboard-page-container.gil-dashboard {
          overflow: visible;
          > .home-page-padding {
              margin-left: -10px;
              margin-right: -10px;
              padding-top: 24px !important;
          }
        }
      }
    }

    .slider-mob-inner {
      flex-wrap: wrap;
    }

    .community-section-inner {
      overflow: visible;
      padding-left: 0;
      justify-content: center;
    }

    .group-container {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: $hd-tab) {
  .article-page-side-outer.full-sidebar {
    .article-page-side.h-100 {
      > .home-page-padding {
        padding: 0 24px 24px;
      }
      > .mt-3.pb-4 {
        > .h-md-100.scroll-y.dashboard-page-container.gil-dashboard {
          overflow: visible;
          > .home-page-padding {
              margin-left: -10px;
              margin-right: -10px;
              padding-top: 24px !important;
          }
        }
      }
    }

    .slider-mob-inner {
      flex-wrap: wrap;
    }

    .community-section-inner {
      overflow: visible;
      padding-left: 0;
      justify-content: center;
    }

    .group-container {
      flex-wrap: wrap;
    }
  }
}

.see-all-sidebar {
  max-width: 100%!important;
}

.bg-alternate-grediant {
  background-image: linear-gradient(180deg, #F3C578 0%, #E49343 100%);
}

.btn-payement {
  background-color: #fff;
  color: $alternate-color;
}

.credit-card-white {
  .card-payment-info {
    color: #000;
    background: #fff;
    box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.50);
    border-radius: 5px;
    padding: 24px !important;
    width: 360px;

    .font-20 {
      font-size: 16px;
      font-weight: 500;
    }

    img {
      display: none;
    }
  }

  .card-payment-edit {
    background-color: $primary;
    margin-top: 24px;
    border-radius: 2px;
    max-width: 180px;

    &:hover {
      background: linear-gradient(180deg, lighten($primary,24.21%) 15%, $primary 100%) !important;
    }

    .text-primary {
      color: #fff !important;
      font-size: 18px;
    }
  }
}

.billing-payment-form {
  #payment-form {
    max-width: 320px;
    margin: auto;

    input[type="text"],
    input[type="number"],
    #card-number {
      margin-bottom: 34px !important;
    }

    #card-number {
      + .d-flex {
        flex-direction: column;

        #card-expiry {
          width: 100% !important;
          margin-bottom: 34px !important;
        }
      }
    }

    .btn-theme {
      min-height: 46px;
      margin-left: 8px;
    }
  }

  @media (max-width: $fhd-tab) {
    margin-top: 0 !important;
  }

  @media (max-width: $hd-tab) {
    margin-top: 0 !important;
  }
}

#country_id,
#state_id {
  padding-right: 40px;
}