body {
font-family: $font-family-base;
}
.profile  .profile-info {
  border-radius: 0;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.13);
  height: 100%;

  @media (max-width: $hd-tab) {
    min-height: auto;
    height: auto;
  }

  .profile-header {
    border: none;
    background-color: $white;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;

    .profile-left {
      font-family: $font-bold;
      @extend .display-6;
    }

    .profile-right {
      @media (max-width: $tab) {
        position: absolute;
        left: 130px;
        margin-top: -3px;
      }
    }
  }

  .my-profile-img {
    width: 75px;
    height: 75px;
    object-fit: cover;

    @media (max-width: $hd-desktop){
      width: 64px;
      height: 64px;
    }
  }

  .card-title-font {
    font-family: $font-bold;
    @extend .display-6;
  }

  .card-text-margin {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.profile-height {
  min-height: calc(100vh - 90px);
}

.font-normal {
  @extend .font-13;
  line-height: 20px;
  font-family: $font-family-base
}

.font-light-bold {
  @extend .font-13;
  font-family: $font-bold;
}

.text-light-grey {
  color: $light-grey;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-30 {
  font-size: 30px;
}
.profile-text-dark {
  color:$sh-text-dark;
}
.modules-info {
  .modules-header {
    .modules-icons {
      font-size: 24px;
    }
  }

  .card {
    border : none;
    border-radius: 6px;
    background: $card-bg-white;
    box-shadow: 1px 4px 15px 0 rgba(68,68,68,0.30);

    .card-title-font {
      font-family: $font-bold;
      @extend .display-6;
      line-height: 1.1;
    }

    .card-header {
      display: flex;
      @include padding(1rem, 1rem, 0rem, 1rem,);
      background: none;
      border: none;

      .min-width-30 {
        min-width: 30px;
      }

      .width-30 {
        width: 30px;
      }
    }

    .modules-card-text {
      margin-top: 0;
      margin-bottom: .7rem;
    }
  }
}

.modules-font {
  @extend .display-7;
  font-family: $font-bold;
}

.font-subtitle {
  @extend .display-9;
  color: $text-black-muted;
}

.dashboard-profile-container {
  width: 222px;

  @media(max-width: $hd-tab) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.dashboard-data-container {
  width: calc(100vw - 334px);

  @media(max-width: $hd-tab) {
    width: 100%;
  }
}

.menu-item-subtitle {
  max-width: calc(100% - 12px);
}

.cleint-goals-width {
  width: 165px;
  min-height: 132px;
}

.dashboard-cards {
  @media (max-width: $hd-desktop) {
    .card-header{

      .card-title-font {
        font-size: 1rem;
      }

      .min-width-30 {
        min-width: 26px !important;
      }
    }

    .display-9 {
      font-size: 0.75rem;
    }

    .btn-primary {
      font-size: 0.875rem;
      min-height: auto;
      padding: 5px;
    }

    .modules-card-text {
      padding-bottom: 0 !important;
    }

    .card-body {
      padding: 0.25rem 1rem 1rem 1rem;
    }
  }
}

.icon-collapse.btn.btn-primary{
  min-width: auto;
  min-height: auto;
  padding: 0;
  color: $black !important;
  background: transparent !important;
  border: none !important;
  font-size: 40px;
  box-shadow: none !important;
  line-height: 1;

  &:after {
    display: none;
  }
}

.client-profile {
  &-header {
    background-size: cover;
    padding: 26px;
    position: relative;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: $hd-tab) {
        img {
          height: 140px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &-img {
    img {
      width: 112px;
      height: 112px;
      object-fit: cover;
      border: 4px solid #fff;
      background-color: #fff;
    }

    @media (max-width: $hd-tab) {
      margin: auto;

      img,
      .sb-avatar {
        margin-top: 70px;
      }

      .sb-avatar,
      .sb-avatar__text {
        width: 112px !important;
        height: 112px !important;

        div {
          font-size: 48px !important;
        }
      }
    }
  }

  &-location {
    margin-bottom: 12px;
    img {
      height: 15px;
    }
  }

  &-action {
    margin-right: 20px;

    img {
      max-width: 30px;
    }
  }

  &-dropdown{
    display: flex;
    align-items: center;

    &-img {
      width: 39px;
      height: 39px;
      object-fit: cover;

      .dropdown-toggle & {
        width: 36px;
        height: 36px;
        object-fit: cover;
        box-shadow: 0px 0px 0 3px $primary;
      }
    }
  }

  &-toggle {
    background: #FFFFFF;
    box-shadow: 1px 2px 6px 1px rgba(39,34,98,0.22);
    border-radius: 15px;
    min-width: 350px;
    max-width: 350px;
    margin-top: 2px;
    position: absolute;
    right: 20px;
    top: 0;

    @media (max-width: 1100px) {
      min-width: auto;
      box-shadow: none;
      position: relative;
      right: 0;

      > .dropdown-toggle .client-profile-dropdown {
        span.font-bold.ml-3 {
          display: none;
        }
      }

      .toggle-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='8px' height='29px' viewBox='0 0 8 29' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54 (76480) - https://sketchapp.com --%3E%3Ctitle%3EMenu Vertical Icon%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='All-Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='Icons/Menu-Vertical-Icon' transform='translate(-16.000000, -6.000000)' fill='%23373838'%3E%3Cg id='Menu-Vertical-Icon' transform='translate(20.000000, 20.111111) rotate(90.000000) translate(-20.000000, -20.111111) translate(6.000000, 17.000000)'%3E%3Cpath d='M6.22222222,3.11111111 C6.22222222,4.83155556 4.82844444,6.22222222 3.11111111,6.22222222 C1.39222222,6.22222222 0,4.83155556 0,3.11111111 C0,1.39222222 1.39222222,0 3.11111111,0 C4.82844444,0 6.22222222,1.39222222 6.22222222,3.11111111' id='Fill-22'%3E%3C/path%3E%3Cpath d='M17.1111111,3.11111111 C17.1111111,4.83155556 15.7173333,6.22222222 14,6.22222222 C12.2811111,6.22222222 10.8888889,4.83155556 10.8888889,3.11111111 C10.8888889,1.39222222 12.2811111,0 14,0 C15.7173333,0 17.1111111,1.39222222 17.1111111,3.11111111' id='Fill-24'%3E%3C/path%3E%3Cpath d='M28,3.11111111 C28,4.83155556 26.6062222,6.22222222 24.8888889,6.22222222 C23.17,6.22222222 21.7777778,4.83155556 21.7777778,3.11111111 C21.7777778,1.39222222 23.17,0 24.8888889,0 C26.6062222,0 28,1.39222222 28,3.11111111' id='Fill-26'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 6px;
        height: 24px;
        margin-right: 0 !important;
        margin-left: 12px !important;
        background-size: cover;
        transform: none !important;

        img {
          display: none;
        }
      }

      .btn.btn-primary.dropdown-toggle {
        min-width: auto;
        padding: 0 4px;

        &[aria-expanded="false"] {
          background: transparent !important;
        }
      }

      .client-profile-dropdown-img {
        box-shadow: none !important;
        border: 3px solid $primary;
      }
    }

    @media (max-width: $hd-tab) {
      min-width: auto;
      box-shadow: none;
      position: relative;
      right: 0;

      > .dropdown-toggle .client-profile-dropdown {
        span.font-bold.ml-3 {
          display: none;
        }
      }

      .toggle-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='8px' height='29px' viewBox='0 0 8 29' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54 (76480) - https://sketchapp.com --%3E%3Ctitle%3EMenu Vertical Icon%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='All-Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='Icons/Menu-Vertical-Icon' transform='translate(-16.000000, -6.000000)' fill='%23373838'%3E%3Cg id='Menu-Vertical-Icon' transform='translate(20.000000, 20.111111) rotate(90.000000) translate(-20.000000, -20.111111) translate(6.000000, 17.000000)'%3E%3Cpath d='M6.22222222,3.11111111 C6.22222222,4.83155556 4.82844444,6.22222222 3.11111111,6.22222222 C1.39222222,6.22222222 0,4.83155556 0,3.11111111 C0,1.39222222 1.39222222,0 3.11111111,0 C4.82844444,0 6.22222222,1.39222222 6.22222222,3.11111111' id='Fill-22'%3E%3C/path%3E%3Cpath d='M17.1111111,3.11111111 C17.1111111,4.83155556 15.7173333,6.22222222 14,6.22222222 C12.2811111,6.22222222 10.8888889,4.83155556 10.8888889,3.11111111 C10.8888889,1.39222222 12.2811111,0 14,0 C15.7173333,0 17.1111111,1.39222222 17.1111111,3.11111111' id='Fill-24'%3E%3C/path%3E%3Cpath d='M28,3.11111111 C28,4.83155556 26.6062222,6.22222222 24.8888889,6.22222222 C23.17,6.22222222 21.7777778,4.83155556 21.7777778,3.11111111 C21.7777778,1.39222222 23.17,0 24.8888889,0 C26.6062222,0 28,1.39222222 28,3.11111111' id='Fill-26'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 6px;
        height: 24px;
        margin-right: 0 !important;
        margin-left: 12px !important;
        background-size: cover;
        transform: none !important;

        img {
          display: none;
        }
      }

      .btn.btn-primary.dropdown-toggle {
        min-width: auto;
        padding: 0 4px;

        &[aria-expanded="false"] {
          background: transparent !important;
        }
      }

      .client-profile-dropdown-img {
        box-shadow: none !important;
        border: 3px solid $primary;
      }
    }

    .btn-primary {
      background: transparent !important;
      border: none;
      color: black !important;
      width: 100% !important;

      &.dropdown-toggle {
        &[aria-expanded="false"] {
          background: linear-gradient(180deg, #ffffff 0%, rgba($primary,0.15) 100%);
        }
      }

      &:after {
        display: none;
      }

      .toggle-icon {
        transition: 0.3s ease-in-out;

        img {
          width: 17px;
        }
      }
      &[aria-expanded="true"] {
        .toggle-icon {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      border: none;
      width: 100%;
      margin: 0;
      min-height: 390px;

      &.show {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }

      @media (max-width: 1100px) {
        position: fixed !important;
        transform: none !important;
        border: none;
        width: 100%;
        margin: 0;
        top: 58px !important;
        animation: slideLeft 0.6s ease-in-out;
        min-height: auto;
      }

      @media (max-width: $fhd-tab) {
        position: fixed !important;
        transform: none !important;
        border: none;
        width: 100%;
        margin: 0;
        top: 58px !important;
        animation: slideLeft 0.6s ease-in-out;
        min-height: auto;
      }

      @media (max-width: $hd-tab) {
        position: fixed !important;
        transform: none !important;
        border: none;
        width: 100%;
        margin: 0;
        top: 58px !important;
        animation: slideLeft 0.6s ease-in-out;
        min-height: auto;
      }
    }

    &-item {
      padding: 5px 12px;
      cursor: pointer;
      margin-bottom: 16px;
      transition: 0.2s ease-in;
      min-height: 49px;

      &.active {
        background-color: rgba($primary,0.15);
      }

      &:not(.active) {
        &:hover {
          background-color: #F6F6F6;
        }
      }
    }

    &-outer {
      max-height: 75vh;
      overflow: auto;
      @extend .scroll-y;

      @media (max-width: $hd-tab) {
        max-height: initial;
        height: calc(100vh - 120px);
      }

      .network-type-name {
        margin-bottom: 2px;

        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }

  &-more-icon {
    height: 17px;
  }

  &-inner {
    padding: 32px;
    position: relative;
    max-width: 1290px;

    @media (max-width: $hd-tab) {
      padding: 12px;
      display: flex;
      flex-wrap: wrap;

      .nav-tabs {
        margin: 0 auto;
      }

      > .btn-get-started  {
        position: relative !important;
        left: calc(50% - 90px) !important;
        top: -30px !important;
        right: auto !important;
      }
    }

    .article-section,
    .group-container {
      max-width: 100%;
    }

    &.mx-auto {
      .nav-tabs {
        @media (min-width: $hd-tab) {
          padding-left: 6px;
        }
      }
    }

    .nav-tabs {
      display: inline-flex;
      border: 1px solid #dee2e6;
      border-radius: 15px;
      font-size: 14px;

      @media (max-width: $desktop) and (min-width: $hd-tab) {
        padding: 0 24px !important;
      }

      .nav-link {
        box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
        min-width: 127px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        color: $black;

        &.active,
        &:hover {
          border-color: transparent;
          background-color: $primary;
          color: #fff;
          font-family: $font-bold;
        }

        &:not(:last-child) {
          border-right: 1px solid #D6D4E8 !important;
        }

        &:first-child {
          border-radius: 15px 0 0 15px;
        }
        &:last-child {
          border-radius: 0 15px 15px 0;
        }

        @media (max-width:$mobile) {
          min-width: auto;
          font-size: 11px;
          height: auto;
        }

        @media (max-width: $hd-tab) {
          svg {
            margin-right: 10px;
          }
        }
      }

      @media (max-width: $mobile) {
        flex-wrap: nowrap;
        overflow: scroll;
        max-width: calc(100vw - 12px);
        box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
      }
    }

    .tab-content {
      padding: 50px 26px;
      max-width: 1238px;

      @media (max-width: $hd-tab) {
        padding: 26px 15px;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &-btn-create {
    position: absolute;
    top: 25px;
    right: 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
    height: 50px;
    min-width: 150px;

    &.btn-primary-pwa {
      border-radius: 25px !important;
    }
  }
}

.expertise-pill {
  padding: 2px 20px;
  font-size: 0.6875rem;
  border-radius: 15px;
  margin-bottom: 10px;
  color: #fff;
  max-width: max-content;

  &.card-pill {
    margin-left: auto;
    margin-top: -31px;
    position: relative;
    margin-right: 10px;
  }
}

$pills-colors: 'pink' #CB1487,'yellow' #C08603,'peal' #108895,'purple' #8037BF,'orange' #FF4800,'blue' #2674D6,'tomato' #DB5B1A,'green' #098322,'brown' #B11F1F;

@each $name, $color in $pills-colors {
  .bg-dark-#{$name} {
    background-color: $color;
  }
  .text-dark-#{$name} {
    color: $color;
  }
}

.notifications-outer {
  margin-right: 380px;

  @media (max-width: 1100px) {
    margin-right: 10px;
    margin-left: 12px;
  }

  @media (max-width: $fhd-tab) {
    margin-right: 10px;
    margin-left: 12px;
  }

  @media (max-width: $hd-tab) {
    margin-right: 10px;
    margin-left: 12px;
  }

  .btn-none {
    padding: 0;
    min-width: auto;
    box-shadow: none;
    border: none;

    &:after {
      display: none;
    }
  }

  .notification-outer {
    width: 430px;
    border: none;
    border-radius: 0;
    box-shadow: 1px 6px 6px 1px rgba(39, 34, 98, 0.22),
                inset -16px -98px 81px 23px #ffffff,
                inset 0px 2px 4px rgba(0, 0, 0, 0.12);
    top: 58px !important;
    transform: none !important;
    padding: 0;
    overflow: hidden;
    animation: slideLeft 0.6s ease-in-out;
    right: 0 !important;
    left: auto !important;
    position: fixed !important;

    @media (max-width: $hd-tab) {
      width: 100vw;
      left: 0px !important;
      right: auto !important;
      position: fixed !important;
      bottom: 0 !important;
      top: 70px !important;
      animation: slideUp 0.6s ease-in-out;
      border-radius: 15px 15px 0 0;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
      padding-bottom: 60px;
    }
  }
}

.notification-overflow {
  height: calc(100vh - 59px);
  background: #f6f6f6;

  @media (max-width: $hd-tab) {
    max-height: 100% !important;
    background-color: #fff;
  }
}

.notification-indicator {
  width: 14px;
  height: 14px;
  background: $notification-color;
  right: -3px;
  z-index: 1;
  top: 5px;
}

.notification-indicator-profile-icon {
  width: 14px;
  height: 14px;
  //background: $notification-color;
  right: 0px;
  z-index: 1;
  top: 0px;
}

.img-border {
  border: 1px solid #999999;
}

.notification-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  object-fit: cover;
}

.notification-item-inner {
  padding: 12px;
  &:hover {
    background-color: #E4E3EB;
    cursor: pointer;
  }

  @media (max-width: $hd-tab) {
    border-bottom: 1px solid #ccc;

    .dropdown {
      .btn-none {
        min-width: auto;

        &:after {
          display: none;
        }
      }
    }
  }
}

.notification-horizontal-menu-img {
  height: 6px;
}

.notification-dropdown {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    border-radius: 15px;
    box-shadow: 1px 2px 6px 1px rgba(39,34,98,0.22);
    border: none;
    padding: 20px 0;
    min-width: 260px;

    &-section {
      padding: 0 20px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      border: 15px solid transparent;
      border-bottom-color: white;
      top: -26px;
      right: 15px;
    }
  }

  &:hover,
  &[aria-expanded="true"] {
    background-color: #ABA8C1;
  }
}

.disable-calling {
  pointer-events: none;
}

.passport-info-outer {
  background: #FFFFFF;
  box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
  border-radius: 15px;
  padding: 24px 20px;
  width: 345px;
  margin-right: 14px;
  margin-bottom: 16px;
  min-height: 200px;

  &-lg {
    width: 420px;
  }

  @media (max-width: $mobile) {
    margin-right: 0;
    width: 100%;
    padding: 24px 12px;
    min-height: auto;
  }
}

.passport-pill {
  border-radius: 25px;
  padding: 10px 24px;
  min-width: 135px;
  text-align: center;

  @media (max-width: $hd-tab) {
    min-width: auto;
  }
}

.interest-inner,
.community-outer,
.article-outer,
.my-group {
  box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
  border-radius: 15px;
  color: #383838;
}

.interest-inner {
  width: 152px;
  margin-bottom: 20px;
  margin-right: 20px;

  img {
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  @media (max-width: $hd-tab){
    width: 140px;
    min-width: 140px; 
    margin-right: 10px !important;

    img {
      height: 74px;
    }
  }
}

.community-outer {
  width: 203px;
  height: 180px;

  &:not(:last-child) {
    margin-right: 14px;
  }

  .display-8.font-bold.mb-1 {
    + .font-12 {
      min-height: 18px;
    }
  }
  .community-avatar {
    &.sb-avatar.sb-avatar--text {
      height: 0 !important;
    }
    .community-avatar {
      margin-top: -55px;
      margin-left: 16px;
      border: 4px solid #fff;
    }
  } 
}

.interest-text {
  padding: 17px 12px;

  @media (max-width: $hd-tab) {
    padding: 11px 12px;
  }
}

.community-card-bg {
  width: 100%;
  height: 60px;
  object-fit: cover;
}

.community-card-pic {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border: 4px solid #fff;
  margin-top: -55px;
  border-radius: 50%;
  cursor: pointer;
}

.shared-interest-outer {
  img {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border: 2px solid #fff;

    &:not(:first-child) {
      margin-left: -10px;
    }
    @for $i from 1 through 20 {
      &:nth-last-child(#{$i}) {
        position: relative;
        z-index: $i;
      }
    }
  }
}

.text-alternate {
  color: $alternate-color !important;
}

.community-chat-icon {
  width: 20px;
  margin-top: 6px;
}

.article-outer {
  width: 264px;
  background-color: #fff;
  position: relative;

  &:not(:last-child) {
    @media (min-width: $hd-tab) {
      margin-right: 14px;
    }
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-right: 0;
  }
}

.article-pic {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
}

.article-title {
  line-height: 1.3;
}

.pwa-profile {
  &-outer {
    .notification-indicator {
      background: $notification-color;
    }
  }

  &-head {
    padding: 50px 20px 30px;
    position: relative;
    min-height: 216px;

    &-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  &-user-img {
    width: 86px;
    height: 86px;
    object-fit: cover;
    background: #fff;
    box-shadow: 0 0 0 8px #FF961E,
                0 12px 19px 0 rgba(60,128,209,0.09);
  }

  &-testimony-user-img {
    width: 86px;
    height: 86px;
    object-fit: cover;
    background: #fff;
    box-shadow: 0 0 0 6px #FF961E,
                0 6px 9px 0 rgba(60,128,209,0.09);
  }

  &-inner {
    border-radius: 15px 15px 0 0;
    margin-top: -16px;
    background: white;
    padding: 20px 6px;
    @media (max-width: $tab) {
      height: calc(100vh - 330px);
      overflow: auto;
      
      .client-profile-inner {
        text-align: center;
      }

      .tab-content {
        text-align: left;

        @media (max-width: $hd-tab) {
          .home-page-heading {
            padding: 0;
          }
        }
      }
    }

    .go-back-icon {
      position: relative !important;
      margin-right: 10px;
      padding-left: 20px;
      left: auto;
      img {
        margin-right: 10px;
        filter: brightness(0);
        width: 18px;
      }

      + h4 {
        font-size: 20px;
      }
    }

    .bottom-text-position {
      margin-top: -20px !important;
    }

    h1.font-20-bold.text-center.my-5 {
      margin-top: 0 !important;
    }

    .pwa-login-screen {
      min-height: auto;
      padding-bottom: 0 !important;
    }
  }

  &-ac-item {
    border-radius: 25px;
    box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
    margin-bottom: 30px;

    .notification-indicator {
      bottom: 5px;
      top: auto;
      left: 48px;
    }

    .font-bold.display-7 {
      line-height: 1.3;

      + .font-14:not(:empty) {
          margin-top: 4px;
        }
    }

    .client-profile-dropdown {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  &-dropdown-img {
    width: 70px;
    height: 70px;
    object-fit: cover;

    &-ac {
      width: 50px;
      min-width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  &-action-icon {
    width: 18px;
    cursor: pointer;
    margin-left: auto;
  }

  &-more-icon {
    width: 7px;
    margin-right: 5px;
  }

  &-toggle {
    transition: 0.3s ease-in-out;

    &.open {
      transform: rotate(180deg);
    }
  }

  &-ac-info {
    max-width: calc(100% - 100px);
  }

  &-account-change {
    // margin-top: -50px;
  }

  &-accounts {
    .client-profile-toggle-outer {
      @media (max-width: $hd-tab) {
        height: auto;
      }
    }
  }

  &-section {
    margin-top: -30px;

    > .text-center {
      > .pwa-sm-btn {
        margin-top: 30px;
      }
    }
  }

}

.pwa-notification {
  .notification-indicator {
    top: -5px;
  }
}

.pwa-heading-img {
    width: 28px;
    margin-right: 5px;
}

.pwa-upload-img {
  border: 8px solid #FF961E;
  background-color: #FF961E;
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.btn-italic {
  font-family: $font-light-italic !important;
}

.home-page-padding {
  padding: 0 28px 38px;

  @media (max-width: $hd-tab) {
    padding: 0;

    .interest-section {
      padding: 0 20px 4px;
      margin-bottom: 0 !important;
    }

    .mb-42 {
      margin-bottom: 0;
    }
  }
}

.resources-outer {
  width: 528px;
  border-radius: 15px;
  padding: 25px;
  min-height: 265px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  &:not(:last-child) {
    margin-right: 22px;

    @media (max-width: $hd-tab) {
      margin-right: 0;
    }
  }

  @media (max-width: $hd-tab) {
    width: 100%;
  }
}

.calender-bg {
  background-image: url(../assets/images/profile/calendar-bg.jpg);
}

.workshop-bg {
  background-image: url(../assets/images/profile/workshop-bg.jpg);
}

.resources-bg {
  background-image: url(../assets/images/profile/resources-bg.jpg);
}

$btn-colors: 'orange' #E2902F,'blue' #0070BA,'green' #5AAD72;

@each $name, $color in $btn-colors {
  .btn-#{$name} {
    background-color: $color !important;
    border-color: $color !important;

    &:hover {
      color: $color !important;
    }
  }
}

.btn-resources {
  min-width: 225px;
  font-size: 20px;
  border-radius: $pwa-btn-border-radius;
}

.welcome-card-img {
  height: 300px;
  max-width: 848px;
  border-radius: 15px;
  box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
  margin-right: 15px;
  width: 404px;
  object-fit: cover;

  @media (max-width: $hd-tab) {
    width: 100%;
    height: 150px !important;
    object-position: bottom;
    box-shadow: none;
    border-radius: 0px;
  }
}

.welcome-card {
  height: 300px;
  max-width: 848px;
  border-radius: 15px;
  box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
  margin-right: 15px;

  @media (max-width: $hd-tab) {
    min-width: 100%;
    flex-direction: column;
    height: auto;
    margin-right: 20px;
  }

  &-section {
    overflow: auto;
    padding: 38px 0 42px 28px;
    position: relative;

    @media (max-width: $hd-tab) {
      padding: 20px;
      margin-bottom: 20px;
      overflow: initial;
    }

    &-outer {
      @media (max-width: $hd-tab) {
        overflow: auto;
        padding: 20px;
      }
    }

    .alice-carousel {
      .alice-carousel__stage-item {
        max-width: 57vw;

      }
    }
  }

  &-img {
    width: 404px;
    object-fit: cover;

    @media (max-width: $hd-tab) {
      width: 100%;
      height: 110px;
      object-position: bottom;
    }
  }

  &-heading {
    &-img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  &-text {
    margin: 25px 0;

    @media (max-width: $hd-tab) {
      display: none;
    }
  }

  &-body {
    @media (max-width: $hd-tab) {
      width: 100%;
      margin-bottom: 10px;

      .text-right {
        margin-right: 10px;
      }
    }
  }
}

.article-section-outer,
.community-section-inner {
  @media (max-width: $hd-tab) {
    overflow: auto;
    padding-left: 20px;
    // transform: translateX(-20px);
    // width: calc(100% + 40px);

    .article-outer, .additional-card {
      min-width: 264px;
      margin-right: 14px;
    }

    .community-outer {
      min-width: 203px;
    }
  }
}
.article-page-suggested-reads {
  .article-section {
    .article-section-outer {
      @media (max-width: $hd-tab) {
        padding-left: 0px;
      }
    }
  }
}

.home-page-heading {
  color: #1D2025;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6px;

  @media (max-width: $hd-tab) {
    padding: 0 20px;
    font-size: 21px;
  }
}
.group-home, .event-home {
  .home-page-heading {
    @media (max-width: $hd-tab) {
      padding: 0;
    }
  }
}
body .gil-dashboard {
  @media (max-width: $hd-tab) {
    padding: 0;

    .additional-resources {
      padding: 0 20px;
    }

    .show-on-pwa {
      display: none !important;
    }
  }
}

.see-all-text {
    color: $primary;
    font-family: $font-med-italic;
    cursor: pointer;
    text-align: right;

    @media (min-width: $hd-tab) {
      width: 150px;
    }
}

.visibility-hidden {
  visibility: hidden;
}

.shar-form.search-form {
    input[type="text"]::placeholder,
    input[type="number"]::placeholder,
    input[type="email"]::placeholder,
    input[type="password"]::placeholder,
    textarea.form-control::placeholder,
    select.round.form-control,
    .article-dropdown .btn-none,
    .event-date-picker input,
    .form-type-selected  span.font-bold.display-8.text-dark,
    input[type="tel"]::placeholder {
      font-family: $font-medium !important;
      font-size: $pwa-web-placeholder-text !important;
      color: #344356;
    }

  &-pwa {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    select.round,
    .form-type-selected,
    .article-dropdown,
    textarea.form-control,
    .pwa-upload-field,
    #card-number,
    #card-expiry,
    #card-cvc,
    .demo-wrapper  {
      height: $pwa-input-height;
      border-radius: $pwa-input-border-radius;
      box-shadow: $pwa-input-box-shadow;
      padding: $pwa-input-padding-y $pwa-input-padding-x;
      transform: translateX(-16px);
      width: 540px;

      @media (max-width: $hd-tab) {
        width: 100%;
        transform: none;
      }
    }

    &.sidebar-search {
      input[type="text"] {
        width: 315px;
        margin-left: auto;
        transform: translateX(0);

        @media (max-width: $hd-tab) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    &.sidebar-form {
      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="password"],
      input[type="tel"],
      select.round,
      .form-type-selected,
      .article-dropdown,
      textarea.form-control,
      .pwa-upload-field,
      #card-number,
      #card-expiry,
      #card-cvc,
      .demo-wrapper {
        width: 315px;
        transform: translateX(0);
        margin-left: 5px;
        margin-bottom: 12px;

        @media (max-width: $hd-tab) {
          width: 100%;
          margin-left: 0;
        }
      }

      &.search-form-full {
        input[type="text"] {
          width: 100%;
          max-width: calc(100% - 10px);
          margin-right: 5px;
        }
      }
    }

    select.round {
      background-position: $pwa-select-arrow-position;
      background-size: $pwa-select-arrow-size;
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-type-selected {
      min-height: 55px;
    }

    textarea.form-control {
      height: 196px;
      @extend .scroll-y;
    }
  }
}

.text-border-right {
  padding-right: 12px;
  border-right: 3px solid currentColor;
  margin-right: 12px;
}

.followers-list-web {
  max-width: 1100px;
}

.article-main-img-wrap {
  min-height: 115px;
  > img {
    width: 100%;
    height: 115px;
    object-fit: cover;
  }
}

.article-section,
.community-section {
  max-width: 1316px;

  @media (min-width: $hd-tab) {
    display: block;
  }
}

.community-section {
  max-width: 1316px;
}

.community-field-text {
  padding: 0 14px;

  .text-right {
    margin-top: 16px;

    .mr-4 {
      margin-right: 0 !important;
    }
  }
}

.my-group {
  width: 315px;
  margin-bottom: 20px;

  @media (max-width: $hd-tab) {
    width: 100%;
    min-width: 100%;
    margin-right: 20px;
  }

  &-img {
    height: 188px;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  &-edit {
    right: 4px;
    bottom: 20px;
    width: 30px;
    height: 30px;

    img {
      height: 20px;
    }
  }
}

.shar-form {
  select.round.form-control {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
}

.web-create-button {
  right: auto !important;
  left: 1000px !important;

  @media (max-width: $hd-tab) {
    position: relative;
    left: auto !important;
    margin-top: -50px;
    margin-bottom: 60px;
    float: right;
  }
}

.ac-activated-border {
  border: 4px solid $primary !important;
}

.member-info-section {
  &-outer {
    max-width: calc(100% - 210px);
  }

  &-data {
    &:not(:empty) {
      margin-top: 8px;
    }
  }
}

.group-invitation-pending {
  background: rgba(249, 249, 249, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 16px 8px;
  color: $primary;
  font-weight: $font-medium
}

.creatable-card-image {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.creatable-card-title {
  min-height: 42px;
}

.hide-tab {
  > .nav.nav-tabs {
    display: none;
  }
}

.slider-mob {
  @media (max-width: $hd-tab) {
    transform: translateX(-16px);
    width: calc(100% + 36px);

    .community-section-inner,
    .article-section-outer {
      &:after {
        content: '';
        min-width: 20px;
      }
    }
  }
}

.slider-mob-inner {
  @media (max-width: $hd-tab) {
    &:after {
      content: '';
      min-width: 1px;
    }

    .community-outer {
      &:last-child {
        margin-right: 14px;
      }
    }
  }
}

.topics-outer {
  @media (max-width: $hd-tab) {
    padding-left: 0;
    margin-top: 24px;
    overflow: visible;
  }
}

.topics-section-outer {
  max-width: 1316px;
}

.time-ago {
  min-width: 48px;
  display: inline-block;
}

.email-icon {
  height: 29px;
}
.pwa-email-icon {
  height: 20px;
}
.chat-icon {
  width: 24px;
}
@media(min-width:1024px){
  .client-profile-changed {
    .client-profile {
      &-header-outer {
        background: #FFFFFF;
        background: linear-gradient(to bottom,  rgba($primary,0.4) 0%, #FFFFFF 50%,#FFFFFF 100%);
      }

      &-header {
        background-size: cover;
        padding: 26px;
        position: relative;
        max-width: 1290px;

        &-bg {
          position: static;
          top: 0;
          left: 0;
          width: 100%;
          height: 300px;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          overflow: hidden;
          box-shadow: 0 2px 4px rgba(0,0,0,0.5);
          min-height: 300px;
          img {
            width: 100%;
            height: 100%;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            object-fit: cover;
          }
        }
      }

      &-img {
        img {
          width: 220px;
          height: 220px;
          object-fit: cover;
          border: 4px solid #fff;
          background-color: #fff;
        }

        @media (max-width: $tab) {
          margin: auto;
        }
      }

      &-location {
        margin-bottom: 20px;
        img {
          height: 15px;
        }
      }

      &-action {
        margin-right: 20px;

        img {
          max-width: 30px;
        }
      }

      &-dropdown{
        &-img {
          width: 39px;
          height: 39px;
          object-fit: cover;
        }
      }

      &-toggle {
        background: #FFFFFF;
        box-shadow: 1px 2px 6px 1px rgba(39,34,98,0.22);
        border-radius: 15px;
        min-width: 350px;
        max-width: 350px;
        margin-top: 2px;
        position: absolute;
        right: 20px;
        top: 0;

        .btn-primary {
          background: transparent !important;
          border: none;
          color: black !important;
          width: 100% !important;

          &:after {
            display: none;
          }

          .toggle-icon {
            transition: 0.3s ease-in-out;

            img {
              width: 17px;
            }
          }
          &[aria-expanded="true"] {
            .toggle-icon {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          position: relative !important;
          transform: none !important;
          border: none;
          width: 100%;
          margin: 0;
          min-height: 390px;

          &.show {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
          }
        }

        &-item {
          padding: 5px 12px;
          cursor: pointer;
          margin-bottom: 16px;
          transition: 0.2s ease-in;
          min-height: 49px;

          &.active {
            background-color: #E4E3EB;
          }

          &:not(.active) {
            &:hover {
              background-color: #F6F6F6;
            }
          }
        }

        &-outer {
          .network-type-name {
            margin-bottom: 2px;

            &:not(:first-child) {
              margin-top: 24px;
            }
          }
        }
      }

      &-more-icon {
        height: 17px;
      }
      &-inner-wrap {
        position: relative;
        &::before {
          content: "";
          background: #FFFFFF;
          height: 95px;
          position: absolute;
          width: 100%;
          top: 0;
          box-shadow: 3px 3px 3px rgba(0,0,0, 0.3);
        }
      }
      &-inner {
        padding: 32px 0;
        position: relative;
        max-width: 1238px;
        border-top:solid 1px #d8d8d8;

        @media (max-width: 1360px) {
          max-width: 1190px;
          margin: auto;
        }

        .connect-to {
          position: absolute;
          top: 20px;
          right: 0;
          .btn {
            background: $primary;
            border-radius: 50px;
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            border-color: $primary;
            min-width: 154px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5) !important;

            &:hover {
              background: $primary;
              border-color: $primary;
              color: $white;
            }
            img {
              margin-right: 10px;
            }
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 50px;
            min-width: 80px;
            color: $black;

            .icon {
              width: 28px;
              height: 28px;
              display: flex;
              align-items: center;
            }
          }
        }

        .article-section,
        .group-container {
          max-width: 100%;
        }

        .nav-tabs {
          display: inline-flex;
          border: 0px;
          border-radius: 0;
          font-size: 14px;

          .nav-link {
            box-shadow: none;
            min-width: auto;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 0;
            color: $black;
            border: 0 !important;
            font-size: 18px;
            padding-left: 0;
            padding-right: 6px;
            background-repeat: no-repeat;
            background-position: 5px center;
            font-weight: 500;
            transition: 0.3s ease-in-out all;
            font-family: inherit;
            position: relative;

            &:not(:last-child) {
              margin-right: 40px;
            }

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              background-color: $primary;
              bottom: 0;
              left: 0;
              transition: 0.3s ease-in-out;
              transform: scaleX(0);
              transform-origin: left;
            }

            svg,
            img {
              margin-right: 12px;
              transition: 0.2s linear;
            }

            &.active,
            &:hover {
              background-color: inherit;
              color: $primary;
              // font-family: $font-bold;

              &:after {
                transform: scaleX(1);
              }
            }

            @media (max-width:$mobile) {
              min-width: auto;
              font-size: 11px;
              height: 44px;
            }
          }

          @media (max-width: $mobile) {
            flex-wrap: nowrap;
            overflow: scroll;
            max-width: calc(100vw - 12px);
            box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
          }
        }

        .tab-content {
          padding: 50px 0;
          max-width: 1238px;

          @media (max-width: 1360px) {
            max-width: 1190px;
            margin: auto;
          }

          @media (max-width: $desktop) {
            padding: 26px 24px;
          }
        }
      }

      &-btn-create {
        position: absolute;
        top: 25px;
        right: 50px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
        height: 50px;
        min-width: 150px;

        &.btn-primary-pwa {
          border-radius: 25px !important;
        }
      }
    }
  }
}

.profile-edit-verticle {
  width: 20px;
  height: 30px;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 38px;

  @media (max-width: $hd-tab) {
    right: 6px;
  }
}

.create-circle-icon {
  width: 24px;
  height: 24px;
}

.btn-support-team {
  border-radius: 25px;
  font-weight: bold;
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22);
  padding-left: 24px;
  padding-right: 24px;
  color: $primary;
  display: flex;
  align-items: center;
  border: none !important;
  min-width: 300px;
  font-size: 20px;
  height: 50px;
  background-color: #fff;

  &:hover {
    color: $primary;
  }

  &.events-none {
    min-width: auto;
  }

  &:after {
    display: none;
  }

  .toggle-icon {
    width: 15px;
    transition: 0.3s transform;
    transform-origin: center;
  }

  &[aria-expanded="true"] {
    border-radius: 25px 25px 0 0;
    box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22) !important;

    + .dropdown-menu.show {
        bottom: auto !important;
        box-shadow: -1px 8px 6px 1px rgba(39, 34, 98, 0.22);
        border-radius: 0 0 25px 25px;
        overflow: hidden;
        padding-bottom: 0;
        min-width: 300px;
        border: none;
        transform: none !important;
        top: 36px !important;

        @media (max-width: $hd-tab) {
          min-width: 100%;
        }

        .dropdown-item {
          padding: 10px 24px;
          font-size: 18px;

          &.active {
            background-color: #E4E3EB;
            color: #000;
          }
        }
    }

    .toggle-icon {
      transform: rotate(180deg);
    }
  }
}

.search-follower {
  border-radius: 25px;
  padding: 0 25px;
  min-width: 350px;
  box-shadow: 0px 2px 9px 0 rgba(60, 127, 209, 0.2);

  @media (max-width: $hd-tab) {
    min-width: auto;
  }

  &::placeholder {
    color: rgb(87, 87, 87);
    font-weight: 500;
  }
}

.new-follower-card {
  background-image: url(../assets/images/profile/new-profile/follow-bg.png);
  width: 200px;
  height: 240px;
  background-position: center;
  background-size: contain;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.2);
}

.new-follower-image:not(.sb-avatar) {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 5px solid #fff;
  border-radius: 50%;
}

.follower-account-name,
.b-600 {
  font-weight: 600;
}

.btn-icon-inside {
  width: 20px;
}

.side-callout-screen-inner {
  margin: -36px -36px 0;
}

.shar-inner-logo {
  max-width: 300px;
  filter: brightness(0) invert(1);
}

.side-data-inner {
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22);
  border-radius: 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: linear-gradient(180deg, #ffffff 0%, rgba($primary, 0.15) 100%) !important;
  }
}
.passport-sidebar-width {
  max-width: 1050px !important;
}
.user-detail {
  border-bottom: solid 1px $light-grey-bg;
  &-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }
  &-name {
    font-size: 18px;
    font-weight: 600;
  }
  &-citizen {
    font-weight: 14px;
  }
  &-citizen-name {
    font-size: 18px;

    @media (max-width: $mobile) {
      margin-left: 70px;
    }
    span {
      font-weight: 600;
    }
  }
}
.key-info {
  &-img {
    width: 26%;

    @media (max-width: $mobile) {
      width: 50%;
      margin: auto;
    }
  }
  &-text {
    width: 74%;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}
.software-list {
  .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: $sh-blue;
  }
}
#select-key-plan, .city-license {
  background-color: inherit !important;
  background: url(../assets/images/profile/new-profile/arrow.svg) no-repeat 85% center !important;
  border: 0 !important;
  width: 100% !important;
  text-transform: inherit;
  letter-spacing: normal;
  text-align: left;
  &:hover,
  &:focus {
    background-color: inherit !important;
    background: url(../assets/images/profile/new-profile/arrow.svg) no-repeat 85% center !important;
    border: 0 !important;
    box-shadow: none !important;
    &:after {
      display: none;
    }
  }
}
.key-plan {
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22);
  border-radius: 15px;
  .price, .month, .users {
    font-weight: 700;
    color: $primary;
  }
  .users {
    font-size: 22px;

    @media (max-width: $mobile) {
      margin-bottom: 8px;
    }
  }
  .price {
    font-size: 48px;

    @media (max-width: $mobile) {
      font-size: 28px;
    }
  }
  .month {
    font-size: 28px;
  }
}
.citizen-details {
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22);
  border-radius: 10px;
  padding: 15px;
  &-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  .icon {
    height: 16px;
    width: 16px;
    img {
      width: 100%;
    }
  }
}
.btn-primary.city-license {
  box-shadow: 2px 2px 6px 1px rgba(60, 128, 209, 0.3) !important;
  width: 100% !important;
  color: $secondary-color;
  letter-spacing: normal;
  border-radius: 15px;
  background-image: none !important;
  .icon {
    height: 50px;
    width: 50px;
  }
  &:hover, &:focus {
    color:$secondary-color !important;
  }
}
.card-detail {
  .form-control {
    width: 100% !important;
    height: 70px !important;
    border-radius: 15px !important;
    border: solid 1px #D2D2D2 !important;
    box-shadow: 0 12px 19px rgba(60, 128, 209, 0.1) !important;
    &.w-50 {
      width: 50% !important;
    }
  }
}
.check-msg {
  margin-top: 20px;
  position: relative;
  .form-check {
    padding-left: 0;
  }

  input {
    display:none;
  }

  label {
    position:relative;
    padding-left:30px;
    display: inline-block;
    font-size: 18px;
    &:before, &:after {
      content:'';
      position:absolute;
    }
    &:before {
      width:20px;
      height:20px;
      border:1px solid rgba($light-grey, 0.5);
      top:5px;
      left:0;
      border-radius:4px;
    }
    &:after {
      width:0px;
      height:0px;
      transition:width 0.2s;
      top: 11px;
      left: 4px;
      transform:rotate(-45deg);
    }
  }

  input:checked + {
    label {
      &:before {
        background: $primary;
      }
      &:after {
        border:1px solid $white;
        border-width: 0 0 2px 2px;
        height:5px;
        width:12px
      }
    }
  }
}
.box {
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22);
  border-radius: 15px;
}
.city-account {
  &-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.citizen-box {
  max-width: 700px;
  width: 100%;
  background-color: #fff;

  @media (max-width: $hd-tab) and (min-width: $mobile) {
    max-width: 100%;
    margin-left: 6px;
  }

  @media (max-width: $mobile) {
    margin: 20px auto;

    .border-right {
      border: none !important;
    }

    .col-md-8 .d-flex {
      flex-wrap: wrap;
    }
  }

  .key-icon {
    width: 100px;
  }
  .key-detail {
    width: calc(100% - 100px);
    margin-left: 20px;

    @media (max-width: $hd-tab) {
      .btn.btn-primary.btn-get-started {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
      }
    }
  }
}
.title {
  background: $primary;
  color: $white;
  text-align: center;
  font-size: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 5px;
}
.progress {
  height: 5px;
  margin-top: 5px;
  border-radius: 30px;
  .progress-bar {
    border-radius: 30px;
  }
}
.statement {  
  &-icon {
    background: linear-gradient(180deg, lighten($primary, 15%) 0%, $primary 100%);
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    height: 85px;
  }
  .box {
    width: 320px;
    background-color: #fff;
  }
}
.card-payment {

  &-info {
    background: linear-gradient(180deg, lighten($primary, 15%) 0%, $primary 100%);
  color: $white;
  border-radius: 15px 15px 0 0;
  }
  &-edit {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 0;
    border: 1px solid #979797;
    border-radius: 0px 0px 15px 15px;
  }
}

.welcome-title {
  position: relative;

  &:after {
      content: '';
      position: absolute;
      width: 70px;
      height: 3px;
      background: $alternate-color;
      left: 0;
      bottom: 0;
  }
  span {
    max-width: 300px;
  }
}

.icon-side-callout-inner {
  min-width: 100px;
  text-align: center;

  img {
    transform: translateX(-8px);
    width: 40px;

    &.icon-key {
      width: 45px;
    }

    &.icon-mob {
      width: 25px;
    }

    &.icon-code {
      width: 35px;
    }
  }
}

.toolbox-card {
  border-radius: 10px;
  max-width: 360px;

  @media (max-width: $desktop) and (min-width: $mobile) {
    max-width: calc(50% - 20px);
  }

  &-checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &-outer {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.5);
      background: #d7d7d7;

      svg {
        opacity: 0;
      }
    }

    &:checked {
      + .toolbox-card-checkbox-outer {
        background-color: #fff;
          svg {
            opacity: 1;
          }
      }
    }
  }

  &-img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }

  .btn-alternate {
    &:hover {
      &:after {
        background: $alternate-color !important;
      }
    }
  }
}

.welcome-modal-checkbox {
  .custom-control-label {
    font-size: 16px;
    padding-left: 10px;
    padding-top: 5px;

    &::before,
    &::after {
      width: 24px;
      height: 24px;
      box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
    }
  }
}

.hidden-btn {
  display: none;
}

.welcome-page-modal {
  .welcome-detail {
    @media (max-width: 1400px) and (min-width: 769px){
      padding-left: 80px;
    }
  }

  .modal-close-btn {
    top: 70px;
    right: 40px;
  }

  .welcome-page-inner {
    min-height: 680px;
  }
}

.video-play-home {
    top: 50%;
    left: 50%;
    transform: translate(-50%);

    @media (max-width: $mobile) {
      top: calc(50% - 15px);
    }

    &-position {
      transform: translate(50%,-50%);
      z-index: 1;

      @media (max-width: $mobile) {
        transform: translate(-50%);
      }
    }
}

.sb-avatar__text {
  background-color: $primary !important;
}

.client-profile-img {
  @media (min-width: 769px) {
    margin-top: -180px;
  }
}

@media (max-width: $hd-tab) {
  .connect-to {
    order: -1;
    width: 100%;
    justify-content: center;
    display: flex;

    a {
      margin-bottom: 30px;
    }
  }
}

.profile-update-toolbox-icon {
  transform: scale(1.3);
}

.br-15 {
  border-radius: 15px !important;
}