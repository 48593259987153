.article-page {
    &-outer {
        padding: 40px 0 40px 70px;
        max-width: calc(100% - 536px);
        width: 100%;
        transition: 0.3s ease-in-out;

        &:not(.course-page-outer) {
            @media (min-width: $hd-tab) {
                .article-page-back {
                    top: -22px;
                    left: -60px;
                }
            }
        }

        &.course-page-outer {
            @media (min-width: $hd-tab) {
                padding: 0;
            }
            &:not(.article-page-outer-sm) {
                @media (min-width: $hd-tab) {
                    max-width: calc(100% - 0px);
                }
            }
        }

        .article-page-inner-body {
            transition: 0.3s ease-in-out;
        }

        @media (min-width: $hd-tab) {
            &-sm {
                max-width: calc(100% - 496px);
    
                .article-page-inner-body {
                    padding-left: 60px;
                }
            }
        }

        .article-description {
            .display-7 {
                display: none;
            }
        }

        @media (max-width: $hd-tab) {
            padding: 20px 20px 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;

            &.course-page-outer {
                padding-top: 0 !important;

                .article-updated-time {
                    margin-bottom: 16px;
                }

                .courses-back-btn {
                    img {
                        filter: brightness(0.4);

                        .night-mode & {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                .article-page-back.cursor-pointer {
                    display: none !important;
                }

                .article-page-inner-head .article-page-pills {
                    order: 1;
                    margin-top: 10px;
                    margin-bottom: 0 !important;
                }

                .article-data-outer.course-data-outer {
                    order: -1;

                    .font-24-bold {
                        font-size: 24px !important;
                    }
                }
            }

            .article-page-inner-head {
                display: flex;
                flex-direction: column;
                margin-bottom: 14px;
                order: -2;
                .article-page-pills {
                    order: -3;

                    span.mr-4.font-bold {
                        &:empty {
                            display: none !important;
                        }
                    }
                }
            }

            .article-page-inner-body {
                display: flex;
                flex-direction: column;
            }

            .article-description,
            .event-details-outer,
            .article-page-members {
                order: 0;
            }

            .event-details-outer {
                &:not(:empty) {
                    border-bottom: 1px solid #ccc;
                }
            }

            .article-updated-time {
                margin-top: 12px;

                .text-secondary {
                    color: #000 !important;
                }
            }

            .font-24-bold {
                margin-left: 0 !important;
            }
        }
    }

    &-back {
       position: relative;
        top: 10px;
        left: 20px;
        height: 0px;
        z-index: 10;

        &-arrow {
            top: -22px;
            left: -64px;

            @media (max-width: $hd-tab) {
                display: none;
            }
        }

        @media (max-width: $hd-tab) {
            &:not(.event-close-btn) {
                top: -38px;
                right: 8px;
                left: auto;
                background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='PWA-2021' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='6.0.1-Article' transform='translate(-311.000000, -134.000000)' fill='%23000000' fill-rule='nonzero'%3E%3Cpath d='M328.577671,134.415606 C329.131812,133.861465 330.030253,133.861465 330.584394,134.415606 C331.138535,134.969747 331.138535,135.868188 330.584394,136.422329 L330.584394,136.422329 L323.007,144 L330.584394,151.577671 C331.103901,152.097179 331.136371,152.919293 330.681802,153.476626 L330.584394,153.584394 C330.030253,154.138535 329.131812,154.138535 328.577671,153.584394 L328.577671,153.584394 L321,146.007 L313.422329,153.584394 C312.902821,154.103901 312.080707,154.136371 311.523374,153.681802 L311.415606,153.584394 C310.861465,153.030253 310.861465,152.131812 311.415606,151.577671 L311.415606,151.577671 L318.993,144 L311.415606,136.422329 C310.896099,135.902821 310.863629,135.080707 311.318198,134.523374 L311.415606,134.415606 C311.969747,133.861465 312.868188,133.861465 313.422329,134.415606 L313.422329,134.415606 L321,141.993 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                width: 20px;
                height: 20px;
                padding: 0 !important;
                background-size: contain;
    
                svg {
                    opacity: 0;
                }
            }

            .night-mode & {
                filter: invert(1);
            } 
        }
        
        svg {

            .course-page-outer & {
                transform: rotate(45deg) scale(0.7);
                filter: none !important;
    
                path {
                    fill: $primary;
                }
            }

            .border-alternate & path {
                fill: $alternate-color
            }
        }
    }

    &-carousel {
        margin-top: 10px;

        .course-page-outer & {
            border: 4px solid $primary;
            margin-top: 0;

            @media (max-width: $hd-tab) {
                border: none;
            }
        }

        .border-alternate & {
            border-color: $alternate-color;
        }

        @media (max-width: $hd-tab) {
            margin: 10px -20px 0;
        }

        .carousel-indicators {
            margin: 0;
            right: 10px;
            justify-content: flex-end;
            bottom: -70px;
            top: auto;
            width: fit-content;
            margin-left: auto;


            li {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                opacity: 1;
                background-color: #7C7C7C;
                margin: 0 6px;

                &.active {
                    background-color: $primary;
                }
            }
        }

        &-top {
            z-index: 10;
            
            .course-page-outer & {
                right: 50px;

                @media (max-width: $hd-tab) {
                    right: 0;
                }
            }

            @media (max-width: $hd-tab) {
                margin-left: -26px;
            }

            .article-page-add-icon {
                bottom: 0;
                left: 60px;
                z-index: 10;
            }
        }

        &-bottom  {
            bottom: 0;
            background-image: linear-gradient(180deg, rgba(167, 165, 165, 0) 10%, rgba(0, 0, 0, 0.4) 104%);
            z-index: 10;
        }

        &-main-img {
            height: 436px;

            .course-page-outer & {
                @media (min-width: $hd-tab) {
                    height: calc(100vh - 140px);
                }
            }
            object-fit: cover;

            @media (max-width: $hd-tab) {
                height: 240px;
            }
        }

        .player-wrapper{
            height: 100%;
            // height: 490px;
            background-color: black;
            @media (max-width: $tab) {
                // height: 560px;
                height: 100%;
                background-color: black;
            }
            @media (max-width: $mobile) {
                height: 100%;
                // height: 560px;
                background-color: black;
            }
        }

        .react-player {
            background-color: $black;
            min-height: 436px;
            
            .course-page-outer & {
                min-height: calc(100vh - 140px);
                height: calc(100vh - 140px) !important;

                 @media (max-width: $hd-tab) {
                    height: 240px !important;
                    min-height: 240px !important;

                    video {
                        height: 240px !important;
                    }
                }
            }

            video {
                height: 390px !important;
            }

            @media (max-width: $hd-tab) {
                height: 500px !important;
                min-height: 360px !important;

                video {
                    height: 360px !important;
                }
            }
        }

        .carousel-control-prev, 
        .carousel-control-next {
            bottom: auto;
            top: calc(50% - 20px);
            transform: translateY(-50%);
        }

        .justify-content-lg-start {

            .course-page-outer & {
                > .align-items-center {
                    margin-left: auto;
                    margin-right: 0 !important;
    
    
                    &:not(:first-child) {
                        display: none !important;
                    }
                }
            }
        }
    }

    &-members {
        &-img {
            margin-top: -8px;
        }

        @media (max-width: $hd-tab) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-text {
        &::first-letter {
            font-family: $font-bold;
            font-size: 30px;
            margin-right: 5px;
        }
    }

    &-side {
        animation: slideLeft 0.3s ease-in-out;
        &-body {
            padding: 30px;
            @extend .scroll-y;

            @media (min-width: $hd-tab) {
                height: calc(100% - 59px);
                overflow: auto;
            }

            @media (max-width: $hd-tab) {
                .group-inside-sidebar {
                    .mr-20 {
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }

            &-chat {
                height: calc(100% - 156px);

                @media (max-width: $hd-tab) {
                    height: calc(100% - 187px);
                }
            }
        }

        &-chat {
            &-body {
                flex: 1;
            }

            &-left {
                background: #E4EEFF;
                border-radius: 1px 15px 15px 15px;
            }

            &-right {
                background: #EFEFEF;
                border-radius: 15px 1px 15px 15px;

                .night-mode & {
                    background: #8a8a8a;
                } 
            }
        }

        &-actions {
            right: 40px;
            top: 12px;
        }

        &-outer {
            position: absolute;
            width: 440px;
            top:0;
            bottom: 0;
            right: 0;
            background: white;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
            // border: 1px solid #EAEAEA;
            z-index: 19;
            overflow-x: hidden !important;

            @media (min-width: $desktop-lg) {
                width: 540px;
            }

            @media (max-width: $hd-tab) {
                width: 100%;
                top: 70px;
                bottom: 0;
                z-index: 1000;
                border: none;
                box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
                position: fixed;
                border-radius: 25px 25px 0 0;
                transition: 0.3s ease-in-out;

                &:empty {
                    display: none;
                }
            }
        }

        &-head {
            @media (max-width: $hd-tab) {
                border-bottom: none !important;

                svg:not(.chevron-up-icon) {
                    display: none;
                }

                .display-8 {
                    font-size: 18px !important;
                    margin-left: 0 !important;
                    width: 100%;
                    text-align: center;
                }
            }

            .chevron-up-icon {
                display: none;

                @media (max-width: $hd-tab) {
                    display: block;
                    width: 60px;
                    background: #d8d8d8;
                    height: 5px;
                    border-radius: 3px;
                    margin: auto;
                    order: -1;
                    margin-bottom: 24px;

                    g {
                        display: none;
                    }
                }
            }
        }
    }

    &-tabs {
        .nav-tabs {
            display: flex;

            &:empty {
                display: none;
            }

            .nav-link {
                width: 33.33%;
            }
        }

        .tab-content {
            padding: 0;
        }
    }

    &-slides-count {
        @media (min-width: $hd-tab) {
            color: #7C7C7C;
            position: absolute;
            top: -10px;
            right: 90px;
            transform: translateY(-100%);
            line-height: 1.2;
            text-align: right;
        }
        @media (max-width: $hd-tab) {
            order: -1;
            display: flex;
            color: $primary;
            margin-top: 24px;

            .article-page-slides-count-chapter {
                margin-right: 4px;
            }
        }
    }

    &-suggested-reads {
        .article-outer {
            width: 236px;
        }

        .mb-42 {
            @media (max-width: $hd-tab) {
                margin-bottom: 0;
            }
        }
    }
}

.avtar-30 {
    width: 30px;
    height: 30px;
    min-width: 30px;
    object-fit: cover;
}

.content-creator-outer {
    max-width: 330px;

    .follower-info-name {
        max-width: 100%;
    }

    @media (max-width: $hd-tab) {
        max-width: 100%;
    }
}

.form-element-dual .form-group input[type="text"],
#card-expiry,
#card-cvc  {
    width: 151px !important;
    margin-right: 7px;

    @media (max-width: $hd-tab) {
        width: 100% !important;
    }
}

.form-element-dual {
    &-right {
        margin-left: 162px;
    }
}

.pwa-checkbox {
    > .custom-checkbox {
        border: 2px solid #D2D2D2;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 3px;
        cursor: pointer;
        box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);

        .custom-checkbox {
            cursor: pointer;
            padding-left: 0;
        }

        input[type="checkbox"] {
          width: 24px;
          height: 24px;
          cursor: pointer;
          visibility: hidden;
          opacity: 1 !important;
        }

        .checkbox-checked.true:after {
            content: '';
            position: absolute;
            background-image: url('../assets/images/icons/tick-black.png');
            background-size: 16px;
            width: 16px;
            height: 16px;
            visibility: visible;
            background-repeat: no-repeat;
            top: 4px;
            left: 2px;
          }
    }

    @media (max-width: $hd-tab) {
        .font-medium {
            width: calc(100% - 50px);
        }
    }
}

.btn-primary-pwa {
    border-radius: $pwa-btn-border-radius !important;
    box-shadow: $pwa-btn-box-shadow !important;
    letter-spacing: $btn-lg-letter-spacing;
}

.add-btn {
    border-radius: 10px!important;
    min-height: 34px !important;
}

.sidebar-btn {
    margin-left: 150px;

    @media (max-width: $desktop-lg) {
        margin-left: auto;
    }

    @media (max-width: $hd-tab) {
        margin: 30px auto 0;
    }

    &.btn-link {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }
}

.btn-inside-group {
    min-width: 110px;

    &:focus {
        text-decoration: none;
        box-shadow: none;
    }
}

.btn-inside-group-chat {
    min-width: 80px;

    &:focus {
        text-decoration: none;
        box-shadow: none;
    }
}



.follower-tab {
  max-width: 100%;
  color: #212529;
}

.follower-info-outer {
  max-width: calc(100% - 100px);
  color: #212529;
}

.follower-info-name {
    max-width: calc(100% - 80px);
}

.liked-article {
    position: absolute;
    margin-left: -40px;
    animation: flying 2.4s ease-in-out;
    opacity: 0;

    &:nth-child(2) {
        animation-delay: 0.3s;
    }
    &:nth-child(3) {
        animation-delay: 0.5s;
    }
    &:nth-child(4) {
        animation-delay: 0.7s;
    }
    &:nth-child(5) {
        animation-delay: 0.9s;
    }
    &:nth-child(6) {
        animation-delay: 1.1s;
    }
}

@keyframes flying {
    0% {
      transform: scale(0);
      margin-top: 0px;
      opacity: 1;
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      margin-top: calc(-100vh + 150px);
      transform: scale(0);
      opacity: 0;
    }
}

.progress-circle {
    position: absolute;
    left: 12px;
    top: 10px;
    z-index: 1;
    path {
        stroke-dasharray: 816;
        stroke-dashoffset: 0;
        animation: dash 3.9s linear;
        animation-delay: 1.8s;
        stroke: #E36C59;
        opacity: 0;
    }
}

@keyframes dash {
    0% {
      stroke-dashoffset: 816;
      opacity: 1;
    }
    90% {
        stroke-dashoffset: 0;
        opacity: 1;
    }
    91%,
    100%  {
        opacity: 0;
    }
}

#card-number,
#card-expiry,
#card-cvc {
    display: flex;
    align-items: center;

    > div {
        width: 100%;
    }
}


.shar-form.search-form-pwa.sidebar-form .amount-group {
    .form-control {
        &::placeholder,
        & {
            font-size: 24px !important;
            font-family: $font-bold !important;
        }

        &::placeholder {
            color: #a7a7a7;
        }
    }
}

.border-top-sm {
  @media (max-width: $hd-tab) {
    border-top: 1px solid #CCC;
  }
}

.event-info-icons-setter {
    svg {
        width: 24px;
        height: 24px;
    }
}

.sm-input-height {
    max-height: 50px;
}

.article-new  {
    max-width: 275px;

    .article-main-img-wrap {
        min-height: 160px;

        img {
            height: 160px;
        }
    }

    .btn-primary-outlined {
        height: 50px;
        box-shadow: inset 0 0 0 1px $primary !important;
    }

    .article-category {
        min-height: 20px;
    }

    .landing-page-stories &.article-outer {
        margin-bottom: 40px !important;
        border-radius: 2px !important;

        .article-main-img-wrap {
            img {
                border-radius: 2px 2px 0 0 !important;
                height: 200px;
            }
        }

        @media (max-width: $hd-tab) {
            max-width: calc(100% - 15px);
        }
    }
}

.home-page-articles.landing-page-stories:after {
    @media (min-width: $hd-tab) {
        content: '';
        min-width: 305px;
    }
}

.article-data-outer {
    padding: 35px 45px 0 60px;

    @media (max-width: $hd-tab) {
        padding: 20px 0 0 0; 
    }
}

.article-page-outer {
    .landing-page-stories {
        @media (max-width: $hd-tab) {
            flex-wrap: nowrap !important;
            overflow: auto;
            padding-left: 10px;
            transform: translateX(-10px);
            margin-right: -30px;

            &:after {
                content: '';
                display: inline-block;
                min-width: 10px;
            }

            .article-outer {
                min-width: 236px;
                margin-bottom: 50px !important;
                margin-right: 16px;
            }
        }
    }

    .community-section {
        @media (min-width: $hd-tab) {
            + .text-center.mb-42 {
                padding-bottom: 30px;
            }
        }
    }

    &:not(.course-page-outer) {
        .article-page-carousel {
            .carousel-indicators {
                bottom: auto;
                top: -30px;
            }
        }
    }
}

.article-description {
    .word-break-word {
        p {
            margin-bottom: 0 !important;
            span,
            & {
                display: block;
                color: inherit !important;
                background-color: inherit !important;

                // &::first-letter {
                //     font-size: 30px;
                //     font-family: $font-bold;
                // }
            }
            a {
                color: $primary !important;
                background-color: inherit !important;
            }
        }
    }
}

@media (max-width: $hd-tab) {
    .course-page-outer {
        .assignment-list {
            box-shadow: none;
            padding: 0 30px !important;

            .assignment-list-inner {
                img,
                span.font-bold.font-18 {
                    display: none !important;
                }
            }

            .btn-primary {
                margin-top: 0px !important;
                margin-bottom: 30px !important;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
                background-color: rgba($primary,0.8) !important;
                border: none !important;
            }
        }

        .article-page-carousel-top,
        .article-page-carousel-bottom {
            display: none !important;
        } 
    }
}

#campaign_video {
    height: calc(100vh - 140px);
    width: 100%;
    object-fit: contain;
    background: black;

    @media (max-width: $hd-tab) {
        height: 240px;
    }
}

.course-btn-transition {
    transition: 0.3s ease-in-out;
}

@media (min-width: $hd-tab) {
    .course-page-outer {
        #ahura-media-view {
            .react-player {
                > div:not(.react-player__preview) {
                    width: 100%;
                    height: 100%;
                    max-height: calc(100% - 55px);
                    padding-top: 55px;
                }
            } 
        }
    }
}