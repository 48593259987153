.giftCard{

  &-container{
    width: 350px;
  }
  &-img{
    img{
      width: 76px;
      height: 75px;
    }
    margin: 50px 0;
    text-align: center;
  }
  &-donate{
    background: linear-gradient(135deg,#e3e3e3,#fff);
    box-shadow: 12px 13px 10px hsla(0,0%,9%,.23);
    &-body{
      min-height: 218px;
    }
  }
  &-form{
    max-width: 290px;
    input[type="number"],
    select.form-control{
      height: 30px ;
      width: 290px ;
      padding: 0 15px;
      font-size: 16px;
      font-style: italic;
    }
    input[type="number"]::placeholder{
      font-size: 16px;
      font-style: italic;
    }

    input[type="number"]::placeholder,
    input[type="text"]::placeholder{
      color: #d2d2d2;
    }

    .round{
      background-position: calc(100% - 12px) calc(1em + -4px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
    }
  }
  &-input-row{
    input[type="number"]{
      width: 100%;
    }
  }
  &-help{
    right: -14px;
    top: 7px;
  }
  &-helpInfo{
    display: none;
    background: #e1f7ff;
    line-height: 16px;
    box-shadow: 8px 8px 10px hsla(0,0%,9%,.23), 0 0 10px hsla(0,0%,9%,.23);
    border-radius: 15px;
    color: #000;
    transform: translateY(-80%) translateX(35px);
    right: -235px;
    z-index: 20;
    &:before{
      position: absolute;
      content: "";
      bottom: 14%;
      left: -26px;
      z-index: 10;
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-right: 40px solid #e1f7ff;
      border-bottom: 30px solid transparent;
    }
  }
}

.success-icon {
    width: 70px;
    margin: 0px 0px 20px 0px;
}

.cursor-pointer{
  cursor: pointer;
}

.border-radius-20{
  border-radius: 20px;
}

.inputWidthAuto{
  input[type="radio"],
  input[type="checkbox"]{
    width: auto;
    margin-right: 1rem;
  }
}

.link-light-grey{
  color: #d2d2d2;
  &:hover{
    color: #d2d2d2;
  }
}

.audience-form{
  input[type="text"],
  input[type="email"],
  input[type="number"]{
    width: 335px;
    height: 35px;
    border-radius: 0;
  }

  button.btn:hover{
    border: 0.125rem solid transparent !important;
  }
}

.giftcard-outer-container{
  max-width: 750px;
}
.giftcard-outer-container2{
  max-width: 775px;
}
.btn-validate{
  text-transform: uppercase;
  color: black;
  font-family: $font-medium;
  min-width: 117px !important;
  height: 35px;
}

.giftcard-social-icon{
  margin: 0 5px;
}

.email-update{
  visibility: hidden;
  pointer-events: none;
}

.focus-none:focus,a:focus{
  outline: none;
}

.btn-update{
    height: 35px;
    padding: 5px;
    min-width: 100px;
    width: 100px;
}
