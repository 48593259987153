body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  font-size: 0.9rem;
  line-height: 1.5;
}

* {
  font-family: $font-family-base;
}

a {
  cursor:pointer;
  &:hover {
    text-decoration: none;
  }
}

iframe {
  vertical-align: top;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

img,
svg {
  max-width: 100%;
}

input,button {
  &:focus {
    border: none;
    outline: none;
  }
}

video {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
