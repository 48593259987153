.shar-icon {
  transition: 0.2s ease-in-out;
  &:hover{
    g{
      fill: $primary;
    }
  }
}

.shar-card {
  box-shadow: 0 4px 8px 0 rgba(5,13,30,0.15);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 4px;
  
  &:hover {
    box-shadow: 1px 7px 15px 0 rgba(68,68,68,0.30);
  }
}

.shar-shadow-inset {
  box-shadow: inset -1px -3px 6px rgba(0, 0, 0, 0.08);
}