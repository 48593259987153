.mp {
  &-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 175px;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: $mobile) {
      height: 200px;
    }
  }

  &-slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &-slide {
    display: inline-block;
    height: 100%;
    width: 50%;
    border-radius: 4px;
    margin-right: 6px;
    padding: 20px 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 60%;

    @media (max-width: $desktop) and (min-width: $hd-tab) {
      width: 100%;
    }

    @media (max-width: $tab) {
      width: 100%;
    }
  }

  &-arrow {
    font-size: 2.5rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 1;
  }

  &-nextArrow {
    position: absolute;
    right: 0;
    color: #fff;
    opacity: 0.8;
    background-image: linear-gradient(90deg, rgba(0,0,0,0.00) 0%, #000000 91%);
  }

  &-backArrow {
    position: absolute;
    left: 0;
    color: #fff;
    background-image: linear-gradient(90deg, #000000 0%, rgba(0,0,0,0.00) 91%);
  }

  &-banner-text {
    max-width: 300px;

    p {
      white-space: normal;
    }
  }
}
