.border-dotted-bottom{
  border-bottom: 1px dotted $text-grey;
}

.network-card-header {
  .card-header {
    padding: 0;
  }

  .card-img-top {
    margin-top: 0 !important;
  }

  > .pl-4 {
    padding-left: 0 !important;
  }
}

.network-profile-card {
  @media (max-width: $tab) {
    .my-profile-img {
      order: -1;
    }

    .icon-collapse {
      position: absolute;
      left: 90px;
      top: 14px;
    }
  }
}

.shar-form-element  {
  div {
    max-height: 100%;
  }

  .css-1g48xl4-IndicatorsContainer {
      display: none;
  }

  .css-1hwfws3 {
      padding: 6px 12px;
  }

  .css-1wa3eu0-placeholder {
    color: #444;
    font-family: $font-light-italic;
    top: auto;
    transform: translateY(0);
    font-size: $input-placeholder-text;
    position: relative;
    
    @media (max-width: $hd-tab) {
      font-size: $pwa-input-placeholder-text;
    }
  }

  .css-1pahdxg-control {
    box-shadow: none;
    // .css-1wa3eu0-placeholder {
    //   padding-top: 16px;
    // }

    &:hover,
    &:focus {
      border:1px solid $primary;
    }
  }
}

.btn-cancel {
  width: 130px;
}
