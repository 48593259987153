.dropdown_primary {
  @include padding (18px, 40px,0px,28px);

  .dropdown:focus {
    outline: none;
  }

  .dropdown-toggle::after {
    margin-left: 2.255em;
  }

  .dropdown-item {
    @include padding (.75rem, .8rem,.75rem,.8rem);
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn {
    @include padding(0.175rem, .8rem, .175rem, 0.8rem);
    color: $white;
  }

  .border-transparent {
    border: 1px solid transparent;
  }

  .custom-dropdown  .lists-position {
    position: absolute;
    width: inherit;
    will-change: transform;
    top: 0px;
    left: -10px!important;
    transform: translate3d(0px, 38px, 0px);
  }

  .custom-dropdown {
    border: 1px solid transparent;
    background-color: #2bc4f3;
    color: $white;
    border-radius: 4px 4px 4px 4px;
    width: 160px;
  }

  .custom-dropdown.show.dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px 4px 0px 0px;
  }

  .show.dropdown .dropdown-menu {
    background: $primary;
    margin: 0;
    color: $white;
    border-radius: 0rem 0rem .25rem .25rem;
    border-top: 0;
  }

  a {
    color: $white;
  }
}

.recent-activity {

  @include padding (18px, 40px,0px,28px);

  .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }

  .align-inherits {
    text-align: inherit;
  }

  .dropdown:focus {
    outline: none;
  }

  .dropdown-item {
    @include padding (.75rem, .8rem,.75rem,.8rem);
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn {
    @include padding(.375rem, .15rem, .375rem, 0.8rem);
  }

  .border-transparent {
    border: 1px solid transparent;
  }

  .custom-dropdown  .lists-position {
    position: absolute;
    width: inherit;
    will-change: transform;
    top: 0px;
    left: -1px!important;
    transform: translate3d(0px, 38px, 0px);
  }

  .custom-dropdown {
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;
    padding-left: 9px;
    width: 160px;
  }

  .custom-dropdown.show.dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }

  .show.dropdown .dropdown-menu {
    background: $white;
    margin: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0rem 0rem .25rem .25rem;
    border-top: 0;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #caf0fa;
    @include transition(all ease-in-out 0.5s);
  }

}

.shar-dropdown{
  &:after{
    border-top: none;
    width: 22px;
    height: 8px;
    margin-left: 10px;
    background:{
      image: url('../assets/images/icons/chevron_down.svg');
      repeat: no-repeat;
      size: contain;
    }
  }
}

.select-add-network {
  .custom-dropdown {
    .dropdown-toggle {
      font-family: $font-medium;
      display: flex;
      align-items: center;

      &:after {
        border: none;
        width: 12px;
        height: 7px;
        margin-left: 20px;
        transition: 0.2s ease-in-out;
        background:{
          image: url('../assets/images/icons/chevron_down.svg');
          repeat: no-repeat;
          size: contain;
        }
      }

      &[aria-expanded="true"] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .lists-position {
    font-size: 0.875rem;
  }
}
